/*
 * このソースコードは blanco Frameworkによって自動生成されています。
 */
import { AbstractRequest, RequestMetaInfo } from "@/oplux/v3/api/common/AbstractRequest"

/**
 * ログアウトAPIの要求電文
 */
export class LogoutPutRequest extends AbstractRequest {
  requestMetaInfo(): RequestMetaInfo {
    return {
      uri: "/api/v3/screen/logout",
      method: "put",
      isAuthenticationRequire: true,
    }
  }

  /**
   * この電文クラス名を文字列で返します。
   *
   * @return この電文のクラス名です
   */
  telegramId(): string {
    return "LogoutPutRequest"
  }
}
