import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"

import { SessionStorageKey } from "@/common/globals"

import store from ".."

@Module({
  dynamic: true,
  store,
  name: "PageTransitDataModule",
  namespaced: true,
})
class PageTransitDataModule extends VuexModule {
  private _inspectionData: any = ""
  private _restoreData: any = ""
  private _location: string = ""
  private _transitDataClearToggle: boolean = false

  /**
   * ロード時に呼び出します
   */
  init(): void {}

  get inspectionTransitData(): any {
    return this._inspectionData
  }

  get restoreTransitData(): any {
    return this._restoreData
  }

  get location(): string {
    return this._location
  }

  get transitDataClearToggle(): boolean {
    return this._transitDataClearToggle
  }

  /**
   * 値変更
   * @param payload
   */
  @Mutation
  private setData(payload: { transData: any; location: string }): void {
    this._inspectionData = payload.transData
    this._location = payload.location
  }

  @Mutation
  private setDataClear(payload: { location: string }): void {
    this._location = payload.location
    this._transitDataClearToggle = !this._transitDataClearToggle
  }

  @Mutation
  private restoreData(initFunc: any): void {
    const restore: any = {}
    let item: any
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i)
      if (key != null && key !== SessionStorageKey.SNACKBAR_QUEUE) {
        item = sessionStorage.getItem(key)
        try {
          restore[key] = JSON.parse(item)
        } catch (e) {
          restore[key] = item
        }
      }
    }
    this._restoreData = restore
    initFunc()
  }

  @Action({ commit: "setData" })
  change(transObj: { transData: any; location: string }) {
    const payload = transObj
    return payload
  }

  @Action({ commit: "setDataClear" })
  clear(transObj: { location: string }) {
    return transObj
  }

  @Action({ commit: "restoreData" })
  initialize(initFunc: any) {
    return initFunc
  }
}

/**
 * ModuleをObject化 => コンポーネントでimportする
 */
export const pageTransitDataModule = getModule(PageTransitDataModule)
