/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import { ValidationMessageTemplate, ValidationRuleFunction, ValidationRuleSchema } from "vee-validate/dist/types/types"

import { passwordValidator } from "./passwordValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール password の定義です。
 * パスワードは、英数字、記号「@%+/\'!#$^?:.(){}[]~-_」のみ使用可能です。
 * 英大文字・英小文字・数字・記号から3種類以上使用が必要です。
 */
export class PasswordRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return passwordValidator(value, params)
  }
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params)
  }
}
