import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [EventItemDisplayFormatEdit]。
 */
export const EventItemDisplayFormatEditRouteConfig: RouteConfig = {
  path: "/EventItemDisplayFormatEdit",
  name: "EventItemDisplayFormatEdit",
  component: () => import("%%/views/eventItemDisplayFormatEdit/EventItemDisplayFormatEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
