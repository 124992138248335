import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorAdd]。
 */
export const OperatorAddRouteConfig: RouteConfig = {
  path: "/OperatorAdd",
  name: "OperatorAdd",
  component: () => import("%%/views/operatorAdd/OperatorAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
