const numberFormats = {
  ja: {
    currency: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
    },
  },
  en: {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    },
  },
}

export default numberFormats
