import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IOperatorRoleList } from "%%/labels/interfaces"

export class OperatorRoleListEn extends AbstractLocaleMessageObject implements IOperatorRoleList {
  readonly index = "No"

  readonly roleId = "Company Role ID"

  readonly roleName = "Company Role Name"

  readonly functionGroup = "Available Functions"

  readonly editAddLabel = "Edit & Register"

  readonly can = "Allowed"

  readonly canNot = "Not Allowed"

  readonly noDataLabel = "No data available."

  readonly totalCount = "Total: {0} items"
}
