import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class WhiteListJa extends AbstractLocaleMessageObject {
  readonly createDate = "作成日付"
  readonly type = "種別"
  readonly unSelected = "未指定"
  readonly ipAddress = "IPアドレス"
  readonly customersBuyerId = "加盟店会員ID"
  readonly tel = "電話番号"
  readonly value = "値"
  readonly valuePlaceholder = "単一入力のみ可能 前方一致で検索"
  readonly createdAt = "作成日時"
  readonly createdBy = "オペレーター名"
  readonly searchButton = "検索"
  readonly deleteButton = "削除"
  readonly deleteTableName = "ホワイト情報"
  readonly toWhiteAddButton = "ホワイト登録（審査なし）"
  readonly notFoundMessage = "該当するデータが見つかりませんでした"
}
