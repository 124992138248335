import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICommonConfirmDialog } from "%%/labels/interfaces"

export class CommonConfirmDialogEn extends AbstractLocaleMessageObject implements ICommonConfirmDialog {
  readonly buttonAdd = "Register"

  readonly buttonUpdate = "Update"

  readonly buttonEdit = "Edit"

  readonly buttonDelete = "Delete"

  readonly buttonBack = "Cancel"

  readonly confirmDialogButtonOk = "Execute"

  readonly confirmDialogButtonCancel = "Cancel"

  readonly defaultTitle = "Confirmation"

  readonly defaultTitleAdd = "Registration Confirmation"

  readonly defaultTitleEdit = "Edit Confirmation"

  readonly defaultTitleDelete = "Delete Confirmation"
}
