import Vue from "vue"
import VueI18n from "vue-i18n"
import validateEn from "vee-validate/dist/locale/en.json"
// vee-validate でデフォルトで定義されているメッセージ
import validateJa from "vee-validate/dist/locale/ja.json"

import dateTimeFormats from "@/i18n/formatters/datetime-formats"
import numberFormats from "@/i18n/formatters/number-formats"
import itemsEn from "@/i18n/items/en"
// 多言語対応項目名
import itemsJa from "@/i18n/items/ja"

import { ValidateMessageEn } from "%%/common/validators/ValidateMessageEn"
// カスタムvalidateメッセージ
import { ValidateMessageJa } from "%%/common/validators/ValidateMessageJa"
// アプリケーションで定義するメッセージ類
import { CommonMessageJa } from "%%/labels/CommonMessageJa"
import { CommonMessageEn } from "%%/labels/en/CommonMessageEn"
import { LabelsEn } from "%%/labels/LabelsEn"
// アプリケーションで定義するラベル類
import { LabelsJa } from "%%/labels/LabelsJa"
import I18nOptions = VueI18n.I18nOptions
import { getLocale } from "%%/i18n/locale"

const resourceJa = {
  labels: new LabelsJa(),
  messages: new CommonMessageJa(),
  fields: itemsJa,
  validation: validateJa.messages,
  opluxValidation: new ValidateMessageJa(),
}

const resourceEn = {
  labels: new LabelsEn(),
  messages: new CommonMessageEn(),
  fields: itemsEn,
  validation: validateEn.messages,
  opluxValidation: new ValidateMessageEn(),
}

Vue.use(VueI18n)
const option: I18nOptions = {
  locale: getLocale(),
  fallbackLocale: "ja",
  messages: {
    ja: resourceJa,
    en: resourceEn,
  },
  dateTimeFormats,
  numberFormats,
}

const i18n = new VueI18n(option)

export default i18n
