import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorList]。
 */
export const OperatorListRouteConfig: RouteConfig = {
  path: "/OperatorList",
  name: "OperatorList",
  component: () => import("%%/views/operatorList/OperatorList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
