import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventAssignDlgEn extends AbstractLocaleMessageObject {
  readonly showDialogButton = "Reviewer Assignment Registration"

  readonly caption = "Assignee Registration"

  readonly assignee = "Reviewer Assignee"

  readonly memo = "Review Memo"

  readonly memoPlaceholder = "Please enter a memo regarding the review within 500 characters"

  readonly registrationButton = "Register"

  readonly cancelButton = "Cancel"
}
