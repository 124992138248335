import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IMenuLabels } from "%%/labels/interfaces"

export class MenuLabelsEn extends AbstractLocaleMessageObject implements IMenuLabels {
  readonly eventList = "Event List"

  readonly eventReportNegative = "Detection Results Report"

  readonly csvAuthori = "CSV Detection/Update"

  readonly negativeList = "Negative Registration"

  readonly whiteList = "White Registration"

  readonly operator = "Management"

  readonly operatorList = "Operator List"

  readonly operatorRoleList = "Role List"

  readonly allowedIpAddress = "Allowed IP Address"
}
