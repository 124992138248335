import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [NegativeAdd]。
 */
export const NegativeAddRouteConfig: RouteConfig = {
  path: "/NegativeAdd",
  name: "NegativeAdd",
  component: () => import("%%/views/negativeAdd/NegativeAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
