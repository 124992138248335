import { LoginOperatorInfo } from "@/models/persistentEntryPoint/LoginOperatorInfo"
import { LoginUserInfo } from "@/models/persistentEntryPoint/LoginUserInfo"

/**
 * ログイン情報のオブジェクト定義です。
 */
export class LoginInfo {
  /**
   * ログイントークン
   */
  loginToken: string = ""

  /**
   * かっこユーザー情報（加盟店オペレータ情報といずれかのみ設定される）
   */
  loginUser?: LoginUserInfo

  /**
   * 加盟店オペレータ情報（かっこユーザー情報といずれかのみ設定される）
   */
  loginOperator?: LoginOperatorInfo

  /**
   * 環境名
   */
  environment: string = ""
}
