import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [SystemError]。
 */
export const SystemErrorRouteConfig: RouteConfig = {
  path: "/SystemError",
  name: "systemError",
  component: () => import("./SystemError.vue"),
  meta: {
    reload: false,
    authRequired: false,
  },
}
