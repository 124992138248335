import { getLocale } from "%%/i18n/locale"

/**
 * 通信に関するメタ情報を表す型です
 */
export class RequestHeader {
  /**
   * 言語
   */
  lang: string = getLocale()

  /**
   * 時間帯
   */
  tz: string = "Asia/Tokyo"

  /**
   * 通貨
   */
  currency: string = "JPY"

  /**
   * バージョン
   */
  version: string = "3.0"
}
