import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { INegativeDetail } from "%%/labels/interfaces"

export class NegativeDetailEn extends AbstractLocaleMessageObject implements INegativeDetail {
  readonly createdAt = "Registration Date and Time"

  readonly addType = "Registration Type"

  readonly eventId = "Event ID"

  readonly customerType = "Customer Type"

  readonly shopMemberId = "Shop Member ID"

  readonly normalizedLastName = "Last Name"

  readonly normalizedFirstName = "First Name"

  readonly zipcode = "Postal Code"

  readonly fullAddress = "Address"

  readonly birthDate = "Date of Birth"

  readonly tel = "Landline Phone Number"

  readonly mobileTel = "Mobile Phone Number"

  readonly mailAccount = "PC Email Account"

  readonly mailDomain = "PC Email Domain"

  readonly mobileMailAccount = "Mobile Email Account"

  readonly mobileMailDomain = "Mobile Email Domain"

  readonly company = "Company Name"

  readonly depart = "Department"

  readonly cookie = "Cookie"

  readonly settleCategory = "Negative Settlement Category"

  readonly otherCategory = "Negative Other Category"

  readonly ipaddress = "IP Address"

  readonly ipaddressv6 = "IP Address v6"

  readonly deviceType = "Device Type"

  readonly primaryDid = "Device ID"

  readonly didMiddle = "DID Middle"

  readonly didShort = "DID Short"

  readonly etag = "Etag"

  readonly localStorage = "Local Storage"

  readonly shopTenantId = "Tenant ID"

  readonly tenantName = "Tenant Name"

  readonly backButton = "Back to List"

  readonly deleteButton = "Delete"

  readonly deleteTableName = "Negative Information"

  readonly empty = "-"

  readonly updatedAt = "Updated Date and Time"

  readonly shopEventId = "Shop Event ID"
}
