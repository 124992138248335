import { AbstractRequest } from "@/oplux/v3/api/common/AbstractRequest"
import { RequestHeader } from "@/oplux/v3/api/common/valueobject/RequestHeader"

/**
 * 共通リクエストを表すオブジェクトです。
 */
export class CommonRequest {
  /**
   * 通信に関するメタ情報
   */
  info: RequestHeader = new RequestHeader()

  /**
   * API毎の要求電文, AbstractRequest を継承してAPI毎に独自の型を指定
   */
  telegram?: AbstractRequest
}
