import { uuid } from "uuidv4"
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"

import { SessionStorageKey } from "@/common/globals"
import { SnackbarAttribute } from "@/models/common/SnackbarAttribute"

import store from ".."

@Module({
  dynamic: true,
  store,
  name: "SnackbarModule",
  namespaced: true,
})
class SnackbarModule extends VuexModule {
  private _snackbarAttributes = new Array<SnackbarAttribute>()

  get snackbar(): boolean {
    return this._snackbarAttributes.length !== 0
  }

  get snackbarAttributes(): Array<SnackbarAttribute> {
    return this._snackbarAttributes
  }

  @Mutation
  private addAttribute(obj: SnackbarAttribute): void {
    obj.id = uuid()
    this._snackbarAttributes.push(obj)
  }

  @Mutation
  private removeAttribute(obj: SnackbarAttribute): void {
    const id = obj.id
    this._snackbarAttributes.forEach((v, index) => {
      if (v.id === id) {
        this._snackbarAttributes.splice(index, 1)
      }
    })
  }

  @Mutation
  private pushSnackbarToQueue(obj: SnackbarAttribute): void {
    obj.id = uuid()
    const item = sessionStorage.getItem(SessionStorageKey.SNACKBAR_QUEUE)
    let queue: Array<SnackbarAttribute>
    if (item === null) {
      queue = new Array<SnackbarAttribute>()
    } else {
      queue = JSON.parse(item)
    }
    queue.push(obj)
    sessionStorage.setItem(SessionStorageKey.SNACKBAR_QUEUE, JSON.stringify(queue))
  }

  @Mutation
  private moveAllQueueToBinding(): void {
    const item = sessionStorage.getItem(SessionStorageKey.SNACKBAR_QUEUE)
    if (item) {
      const queue: Array<SnackbarAttribute> = JSON.parse(item)
      queue.forEach(obj => {
        const newObj = new SnackbarAttribute()
        newObj.color = obj.color
        newObj.timeout = obj.timeout
        newObj.text = obj.text
        newObj.id = obj.id
        newObj.mode = obj.mode
        newObj.snackbar = obj.snackbar
        this._snackbarAttributes.push(newObj)
      })
      sessionStorage.removeItem(SessionStorageKey.SNACKBAR_QUEUE)
    }
  }

  @Action({ commit: "addAttribute" })
  showSnackbar(obj: SnackbarAttribute) {
    return obj
  }

  @Action({ commit: "removeAttribute" })
  hideSnackbar(obj: SnackbarAttribute) {
    return obj
  }

  @Action({ commit: "pushSnackbarToQueue" })
  queueingSnackbar(obj: SnackbarAttribute) {
    return obj
  }

  @Action({ commit: "moveAllQueueToBinding" })
  flushSnackbarQueue() {
    return
  }
}

/**
 * ModuleをObject化 => コンポーネントでimportする
 */
export const snackbarModule = getModule(SnackbarModule)
