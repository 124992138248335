/*
 * このソースコードは blanco Frameworkによって自動生成されています。
 */
import { ScreenPermission } from "@/models/screen/ScreenPermission"
import { AbstractResponse } from "@/oplux/v3/api/common/AbstractResponse"

/**
 * かっこロール情報一覧取得APIの応答電文です。
 */
export class ScreenPermissionListPostResponse extends AbstractResponse {
  /**
   * 権限情報一覧
   *
   * 規定値   [new Array&lt;ScreenPermission&gt;()]
   */
  screenPermissions: Array<ScreenPermission> = new Array<ScreenPermission>()

  static genericScreenPermissions(): string | undefined {
    return "ScreenPermission"
  }

  /**
   * この電文クラス名を文字列で返します。
   *
   * @return この電文のクラス名です
   */
  telegramId(): string {
    return "ScreenPermissionListPostResponse"
  }
}
