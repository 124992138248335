import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventList } from "%%/labels/interfaces"

export class EventListEn extends AbstractLocaleMessageObject implements IEventList {
  readonly idTypeItemShopEventId = "Shop Event ID"

  readonly idTypeItemEventId = "Event ID"

  readonly quickSearchValue = "Quick Search Value"

  readonly searchShopEventIdPlaceholder: string =
    "Max 100 characters. Separate multiple entries with spaces. Searchable with Enter."

  readonly searchEventIdPlaceholder: string =
    "Max 47 characters. Separate multiple entries with spaces. Searchable with Enter."

  readonly searchableWithEnter: string = "Searchable with Enter."

  // Detailed Search が適切な訳だがボタンからはみ出るので短縮版を採用
  readonly detailSearchButton = "Detailed"

  readonly detailSearchAreaTitle = "Detailed Search"

  readonly detailSearchEventAreaTitle = "Event Information"

  readonly ruleCode = "Trigger Rule Code"

  readonly ruleCodePlaceholder = "Max 100 characters. Separate multiple entries with spaces."

  readonly tenantName = "Tenant"

  readonly tenantNamePlaceholder = "Max 200 characters. Separate multiple entries with spaces."

  readonly ruleDescription = "Rule Description"

  readonly ruleDescriptionPlaceholder = "Max 1024 characters. Separate multiple entries with spaces."

  readonly amount = "Settlement Amount"

  readonly amountMinimum = "Settlement Amount"

  readonly amountMinimumPlaceholder = "Numeric characters. Max 9 characters."

  readonly amountRange = "～"

  readonly amountMax = "Settlement Amount"

  readonly amountMaxPlaceholder = "Numeric characters. Max 9 characters."

  readonly settleStatus = "Settlement Status"

  readonly method = "Payment Method"

  readonly issuer = "Card Issuer"

  readonly createdAt = "Automatic Review Date and Time"

  readonly createdAtFromDate = "Date"

  readonly createdAtFromHour = "Hour"

  readonly createdAtFromMinute = "Minute"

  readonly datetimeRange = "～"

  readonly createdAtToDate = "Date"

  readonly createdAtToHour = "Hour"

  readonly createdAtToMinute = "Minute"

  readonly aaresult = "Automatic Detection Result"

  readonly maresult = "Manual Detection Result"

  readonly deadline = "Manual Detection Deadline"

  readonly deadlineFromDate = "Date"

  readonly deadlineFromHour = "Hour"

  readonly deadlineFromMinute = "Minute"

  readonly deadlineToDate = "Date"

  readonly deadlineToHour = "Hour"

  readonly deadlineToMinute = "Minute"

  readonly authoriModel = "Detection Model"

  readonly authStatus = "Detection Status"

  readonly assignee = "Reviewer"

  readonly reason = "Reason for Manual Detection"

  readonly settleCategory = "Negative Settlement Category"

  readonly otherCategory = "Negative Other Category"

  readonly label = "Label"

  readonly detailSearchItemAreaTitle = "Product Information"

  readonly shopItemId = "Shop Product ID"

  readonly shopItemIdPlaceholder = "Max 512 characters. Separate multiple entries with spaces."

  readonly itemName = "Item Name"

  readonly itemNamePlaceholder = "Max 500 characters. Separate multiple entries with spaces."

  readonly price = "Unit Price"

  readonly pricePlaceholder = "Numeric characters. Max 9 characters."

  readonly quantity = "Quantity"

  readonly quantityPlaceholder = "Numeric characters. Max 9 characters."

  readonly category = "Product Category"

  readonly categoryPlaceholder = "Max 20 characters. Separate multiple entries with spaces."

  readonly stock = "Stock Availability"

  readonly detailSearchCustomerAreaTitle = "Personal Information"

  readonly customerTypeItemPurchaser = "Search by Purchaser Information"

  readonly customerTypeItemShipping = "Search by Shipping Information"

  readonly customerTypeItemBoth = "Search by Both Purchaser and Shipping Information"

  readonly shopMemberId = "Shop Member ID"

  readonly shopMemberIdPlaceholder = "Max 100 characters. Separate multiple entries with spaces."

  readonly lastName = "Last Name"

  readonly lastNamePlaceholder = "Max 150 characters (hash value)"

  readonly firstName = "First Name"

  readonly firstNamePlaceholder = "Max 150 characters (hash value)"

  readonly sex = "Gender"

  readonly birthdate = "Date of Birth"

  readonly company = "Company Name"

  readonly companyPlaceholder = "Max 100 characters."

  readonly depart = "Department"

  readonly departPlaceholder = "Max 100 characters."

  readonly existingCustomerFlg = "Existing Customer"

  readonly fullAddress = "Address"

  readonly fullAddressPlaceholder = "Max 200 characters."

  readonly tel = "Phone Number"

  readonly telPlaceholder = "Max 20 characters."

  readonly deliveryCompany = "Delivery Company"

  readonly deliveryCompanyPlaceholder = "Max 100 characters. Separate multiple entries with spaces."

  readonly emailAccount = "Email Account"

  readonly emailAccountPlaceholder = "Max 64 characters (hash value)"

  readonly emailDomain = "Email Domain"

  readonly emailDomainPlaceholder = "Max 253 characters."

  readonly detailSearchDeviceAreaTitle = "Device Information"

  readonly ipaddress = "IP Address"

  readonly ipaddressPlaceholder = "Max 50 characters. Separate multiple entries with spaces."

  readonly cookie = "Cookie"

  readonly cookiePlaceholder = "Max 500 characters. Separate multiple entries with spaces."

  readonly primaryDid = "Device ID"

  readonly primaryDidPlaceholder = "Max 38 characters. Separate multiple entries with spaces."

  readonly userDeviceId = "Terminal ID"

  readonly userDeviceIdPlaceholder = "Max 64 characters. Separate multiple entries with spaces."

  readonly deviceType = "Device Type"

  readonly didShort = "DID Short"

  readonly didShortPlaceholder = "Max 128 characters."

  readonly didMiddle = "DID Middle"

  readonly didMiddlePlaceholder = "Max 128 characters."

  readonly authoriId = "O-MOTION Review ID"

  readonly authoriIdPlaceholder = "Max 64 characters. Separate multiple entries with spaces."

  readonly result = "O-MOTION Review Result"

  readonly closeButton = "Close"

  readonly includeDeleted = "Include Deleted"

  readonly negativeOnly = "Negatives Only"

  readonly searchButton = "Search"

  readonly conditionClearButton = "Clear Conditions"

  readonly tableHeaderShopEventId = "Shop Event ID"

  readonly tableHeaderRelatedCount = "Related Count"

  readonly tableHeaderAuthoriModelId = "Detection Model"

  readonly tableHeaderAaresult = "Automatic Detection Result"

  readonly tableHeaderMaresult = "Manual Detection Result"

  readonly tableHeaderSettleStatus = "Settlement Status"

  readonly tableHeaderCreatedAt = "Automatic Detection Date and Time"

  readonly tableHeaderAmount = "Settlement Amount"

  readonly tableHeaderTenantName = "Tenant"

  readonly tableHeaderShopMemberId = "Purchaser Member ID"

  readonly tableHeaderLastName = "Last Name"

  readonly tableHeaderFirstName = "First Name"

  readonly tableHeaderZipcode = "Postal Code"

  readonly tableHeaderFullAddress = "Address"

  readonly tableHeaderTel = "Landline Phone Number"

  readonly tableHeaderMobileTel = "Mobile Phone Number"

  readonly tableHeaderMailAccount = "PC Email Account"

  readonly tableHeaderMailDomain = "PC Email Domain"

  readonly tableHeaderMobileMailAccount = "Mobile Email Account"

  readonly tableHeaderMobileMailDomain = "Mobile Email Domain"

  readonly empty = "-"

  // Quick Search が適切な訳だがボタンからはみ出るので短縮版を採用
  readonly quickSearchButton = "Quick"

  readonly detailSearchCloseButton = "Close▲"
}
