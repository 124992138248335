import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [PasswordChange]。
 */
export const PasswordChangeRouteConfig: RouteConfig = {
  path: "/PasswordChange",
  name: "PasswordChange",
  component: () => import("%%/views/passwordChange/PasswordChange.vue"),
  meta: {
    reload: true,
    authRequired: false,
  },
  props: route => ({
    token: route.query.token,
  }),
}
