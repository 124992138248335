import { Vue } from "vue-property-decorator"

import { SystemErrorTransit } from "@/models/systemError/SystemErrorTransit"
import { pageTransitDataModule } from "@/stores/modules/PageTransitDataModule"

export enum TransitSystemErrorType {
  // 遷移データが無効である
  INVALID_TRANSIT_INFO,
  // APIのレスポンスがエラーである
  API_RESPONSE_ERROR,
  // 存在しないページに遷移しようとした
  NOT_FOUND,
  // 権限エラー
  FORBIDDEN,
  // その他のエラー
  OTHER_ERRORS,
}

/**
 * Transitのユーティリティ
 */
export class TransitUtils {
  /**
   * システムエラー画面に遷移する
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param type システムエラーのタイプ
   * @param message 補足情報（オプション）
   */
  public static transitToSystemError(
    component: Vue,
    type: TransitSystemErrorType,
    message: any | undefined = undefined
  ) {
    // メッセージ準備
    let mainMessage: string = ""
    switch (type) {
      case TransitSystemErrorType.INVALID_TRANSIT_INFO:
        mainMessage = component.$t("messages.error965").toString()
        break
      case TransitSystemErrorType.API_RESPONSE_ERROR:
        mainMessage = component.$t("messages.error964").toString()
        break
      case TransitSystemErrorType.NOT_FOUND:
        mainMessage = component.$t("messages.error963").toString()
        break
      case TransitSystemErrorType.FORBIDDEN:
        mainMessage = component.$t("messages.error962").toString()
        break
      case TransitSystemErrorType.OTHER_ERRORS:
        mainMessage = message
        break
    }

    // 遷移情報を作成
    const systemErrorTransit = new SystemErrorTransit()
    systemErrorTransit.message = mainMessage

    // 遷移処理（SystemError.routerPathを参照すると循環参照になってしまうので文字列としている）
    pageTransitDataModule.change({
      transData: {
        systemError: JSON.stringify(systemErrorTransit),
      },
      location: "/SystemError",
    })
  }

  /**
   * 任意の画面に遷移する
   * @param _ 使用されてないparam
   * @param location 遷移先
   * @param keyValueArray 遷移情報をキーバリューにした配列
   */
  public static transitToAny(_: unknown, location: string, keyValueArray: { key: string; value: any }[] = []): void {
    const transData: { [i: string]: string } = {}
    keyValueArray.forEach(keyValue => {
      transData[keyValue.key] = JSON.stringify(keyValue.value)
    })

    pageTransitDataModule.change({ transData, location })
  }

  /**
   * 遷移データを復元する
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param key 復元対象のキー
   * @param transitSystemErrorOnInvalid 取得しようとしている情報が無効だった場合にシステムエラー画面に遷移するかどうか（オプション、デフォルト：true）
   * @param checkCallback 追加でチェックが必要な場合のコールバック（オプション）
   */
  public static restoreTransitDataAs<T>(
    component: Vue,
    key: string,
    transitSystemErrorOnInvalid: boolean = true,
    checkCallback: (checkData: T) => boolean = () => true
  ): T | undefined {
    let hasError = true
    let restoredData: T | undefined = undefined

    // 遷移情報取得
    if (key in pageTransitDataModule.restoreTransitData) {
      try {
        restoredData = JSON.parse(pageTransitDataModule.restoreTransitData[key])
      } catch (e) {
        // NOTE: ESLint の Empty block statement no-empty を回避するための応急処置
      }
    }

    // チェック用コールバック
    if (restoredData !== undefined && checkCallback(restoredData)) {
      hasError = false
    }

    // エラー判定（必要に応じてシステムエラー画面に遷移させる）
    if (transitSystemErrorOnInvalid && hasError) {
      this.transitToSystemError(component, TransitSystemErrorType.INVALID_TRANSIT_INFO)
      return undefined
    }

    return restoredData
  }
}
