import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICommonMessage } from "%%/labels/interfaces"

export class CommonMessageEn extends AbstractLocaleMessageObject implements ICommonMessage {
  readonly error999 =
    "Under detection. Please wait for the results.\nTo check the detection status, please view the 'CSV Bulk Detection Registration & Update History' screen."

  readonly error1000 =
    "Updating. Please wait for the results.\nTo check the update status, please view the 'CSV Bulk Detection Registration & Update History' screen."

  readonly info686 = "Logging out.\nAre you sure?"

  readonly error920 = "The new password and its confirmation do not match."

  readonly info677 = "Password change completed successfully. Please return to the login."

  readonly info691 = "Changing password.\nAre you sure?"

  readonly info692 =
    "Please enter a new password.\n<Password Conditions>\n- 8 to 20 characters in length\n- Include at least three of the following: uppercase letters, lowercase letters, numbers, special characters\n<Allowed Special Characters>\n@ % + / \\ ‘ ! # $ ^ ? : . ( ) { } [ ] ~ _ -"

  readonly error902 = "No item selected for update. Please select at least one."

  readonly info693 =
    "Password change request received.\nAn email containing the URL to the password change screen will be sent to the specified email address. Please check."

  readonly info674 = "For operator verification, please enter the Merchant ID and email address."

  readonly info667 = "Copying detection model information.\nAre you sure?"

  readonly info675 =
    "Multiple detection model events are selected.\nPlease select events from a single detection model."

  readonly info689 = "Deleting {0} events.\nAre you sure?"

  readonly info678 = "Registering detection result as HOLD.\nAre you sure?"

  readonly info679 = "Registering detection result as NG.\nAre you sure?"

  readonly info680 = "Registering detection result as OK.\nAre you sure?"

  readonly info684 = "Registering reviewer.\nAre you sure?"

  readonly info685 = "Registering detection memo.\nAre you sure?"

  readonly info681 = "Outputting CSV file.\nAre you sure?"

  readonly info687 = "CSV output request received. A download URL will be sent by email once the file is ready."

  readonly info683 = "Updating negative information.\nAre you sure?"

  readonly error914 = "No labels registered."

  readonly info673 = "Logged into the O-PLUX management screen for production use."

  readonly info695 = "Outputting selected data to a CSV file. Notification of the output file will be sent by email."

  readonly info672 = "Release completed."

  readonly info671 = "Enabled/Disabled toggle completed."

  readonly info670 = "Toggling enabled/disabled.\nAre you sure?"

  readonly info669 = "Automatic release to the staging environment completed."

  readonly info668 = "Executing automatic release to the staging environment.\nAre you sure?"

  readonly info696 =
    "Registration completed.\nAn account confirmation email, containing the login password, will be sent to the registered email address."

  readonly info682 = "Updating label information.\nAre you sure?"

  readonly error922 = "No item selected for update. Please select at least one."

  readonly info666 = "Copy completed."

  readonly error968 = "Not a valid rule"

  readonly info001 = "Registration completed."

  readonly error998 = "A system error has occurred."

  readonly info002 = "Update completed."

  readonly info003 = "Deletion completed."

  readonly info004 = "File upload completed."

  readonly info005 = "File download completed."

  readonly info006 = "Uploading CSV file.\nAre you sure?"

  readonly info007 = "Downloading CSV file.\nAre you sure?"

  readonly info008 = "Registering {0}.\nAre you sure?"

  readonly info009 = "Updating {0}.\nAre you sure?"

  readonly info014 = "Deleting {0}.\nAre you sure?"

  readonly info011 =
    "Registration completed.\nAn account confirmation email, containing the login password, will be sent to the registered email address."

  readonly info012 = "All editing data discarded."

  readonly info013 = "There is editing data.\nPress 'Yes' to register, or 'No' to discard all editing data."

  readonly error993 = "A timeout occurred during processing."

  readonly error991 = "File upload failed.\nPlease try again. If the problem persists, contact the administrator."

  readonly error992 = "File download failed.\nPlease try again. If the problem persists, contact the administrator."

  readonly info676 = "No relevant data found"

  readonly error967 = "Cannot set the condition expression. Please select a condition expression group."

  readonly info015 = "Another user is attempting to update the same information."

  readonly info688 = "Event '{0}' is being edited by another user."

  readonly error965 = "A system error has occurred. (965)"

  readonly error964 = "A system error has occurred. (964)"

  readonly error963 = "404 Not Found. The specified page was not found."

  readonly error962 = "A system error has occurred. (962)"

  readonly error923 = "The group name is duplicated."

  readonly info665 = "Please enter an email address."

  readonly error932 = "There are parts in this category. It cannot be deleted."

  readonly error933 = "Release information cannot be created with only 'Simulation' type rule packages."

  readonly error934 = "There is a duplication in start dates for 'Normal' type rule packages."

  readonly error935 = "Please specify a date and time after the start date and time."

  readonly error936 = "Empty groups cannot be registered. Please register units or delete empty groups"

  readonly error937 = "Empty units cannot be registered. Please register rules or delete empty units"

  readonly info697 = "Data could not be retrieved correctly. Please reload."
}
