import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [EventReportNegative]。
 */
export const EventReportNegativeRouteConfig: RouteConfig = {
  path: "/EventReportNegative",
  name: "EventReportNegative",
  component: () => import("%%/views/eventReportNegative/EventReportNegative.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
