/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import { ValidationMessageTemplate, ValidationRuleFunction, ValidationRuleSchema } from "vee-validate/dist/types/types"

import { domainValidator } from "./domainValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール domain の定義です。
 * 許容するのは、半角英数とハイフンのみ。ラベル（.(ドット）で区切られた文字列）の先頭と末尾にハイフンは使えない。<br>
 * ラベル全体の長さは3文字以上63文字以下。<br>
 * 最低でも1つの「.」が入る、必ず2つ以上のラベルになっていること。
 */
export class DomainRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return domainValidator(value, params)
  }
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params)
  }
}
