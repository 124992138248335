import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ILoginInput } from "%%/labels/interfaces"

export class LoginInputEn extends AbstractLocaleMessageObject implements ILoginInput {
  readonly inputLabelCompanyId = "Company ID"

  readonly inputTextBoxCompanyIdPlaceholder = "Please enter within 50 alphanumeric characters"

  readonly inputLabelMailAddress = "Email Address"

  readonly inputTextBoxMailAddressPlaceholder = "Please enter your email address"

  readonly inputLabelPassword = "Password"

  readonly inputTextBoxPasswordPlaceholder = "Enter using alphanumeric characters and symbols"

  readonly operationButtonForgotPassword = "Click here if you forgot your password"

  readonly operationButtonLogin = "Login"

  readonly error903 = "There is an error in your Company ID, email address, or password, or it is not registered."

  readonly error913 = "Please log in again"
}
