import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorListJa extends AbstractLocaleMessageObject {
  readonly title: string = "加盟店オペレータ情報一覧"

  readonly infoLabelId: string = "加盟店ID"

  readonly operatorId: string = "オペレータID"

  readonly operatorName: string = "オペレータ名"

  readonly roleName: string = "ロール名"

  readonly email: string = "メールアドレス"

  readonly createdAt: string = "作成日時"

  readonly buttonBack: string = "戻る"

  readonly buttonToOperatorAdd: string = "登録"

  readonly buttonDeleteOperator: string = "削除"

  readonly companyIdLabel: string = "加盟店ID"

  readonly operatorCountPrefix: string = "件数:"

  readonly operatorCountSuffix: string = "件"

  readonly pagingDirection: string = "ページ選択:"

  readonly dialogDeleteTitle: string = "削除 確認"

  readonly tableName: string = "加盟店オペレータ"

  readonly resultSelectedCount: string = "件数：{0}件中 {1}件選択"

  readonly allSelection: string = "全件選択（{0}件）されています。"

  readonly allSelectionButton: string = "検索結果の全てを選択"

  readonly allDeselectionButton: string = "全件選択解除"

  readonly tableDropDownPageCount: string = "{count}件表示"

  readonly noData: string = "データがありません"
}
