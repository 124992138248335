import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IPasswordChange } from "%%/labels/interfaces"

export class PasswordChangeEn extends AbstractLocaleMessageObject implements IPasswordChange {
  readonly infoPasswordLabel = "New Password"

  readonly infoPasswordPlaceholder = "Please enter a new password with a minimum of 8 alphanumeric characters"

  readonly infoConfirmPasswordLabel = "Confirm New Password"

  readonly infoConfirmPasswordPlaceholder = "Please re-enter your new password"

  readonly operationOkButton = "Change"

  readonly operationReturnLoginButton = "Return to Login Screen"

  readonly error910 = "This is not a valid URL.\nPlease request a password change again."

  readonly error919 =
    "The display period for the password change screen has expired.\nPlease request a password change again."

  readonly error921 = "Failed to change the password. Please try again."
}
