import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventNgDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "NG"

  readonly caption: string = "審査結果 - NG"

  readonly reason: string = "審査理由"

  readonly memo: string = "審査メモ"

  readonly memoPlaceholder: string = "審査に関するメモを500文字以内で入力してください"

  readonly label: string = "ラベル"

  readonly purchaser: string = "購入者"

  readonly operationTypeItemNothing: string = "何もしない"

  readonly operationTypeItemRegistration: string = "ネガティブ登録する"

  readonly operationTypeItemRelease: string = "ネガティブ解除する"

  readonly shipping: string = "配送先"

  readonly settleCategory: string = "決済カテゴリ"

  readonly otherCategory: string = "その他カテゴリ"

  readonly registrationButton: string = "登録"

  readonly cancelButton: string = "キャンセル"
}
