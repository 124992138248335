import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IOperatorList } from "%%/labels/interfaces"

export class OperatorListEn extends AbstractLocaleMessageObject implements IOperatorList {
  readonly title = "Company Operator Information List"

  readonly infoLabelId = "Company ID"

  readonly operatorId = "Operator ID"

  readonly operatorName = "Operator Name"

  readonly roleName = "Role Name"

  readonly email = "Email Address"

  readonly createdAt = "Creation Date and Time"

  readonly buttonBack = "Back"

  readonly buttonToOperatorAdd = "Register"

  readonly buttonDeleteOperator = "Delete"

  readonly companyIdLabel = "Company ID"

  readonly operatorCountPrefix = "Count:"

  readonly operatorCountSuffix = "items"

  readonly pagingDirection = "Page Selection:"

  readonly dialogDeleteTitle = "Delete Confirmation"

  readonly tableName = "Company Operator"

  readonly resultSelectedCount = "Items: {0} out of {1} selected"

  readonly allSelection = "All {0} items selected."

  readonly allSelectionButton = "Select all search results"

  readonly allDeselectionButton = "Deselect all"

  readonly tableDropDownPageCount = "Show {count} items"

  readonly noData = "No Data Available"
}
