import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonDateSelectionFieldJa extends AbstractLocaleMessageObject {
  readonly defaultPlaceholder: string = "YYYY/MM/DD"

  readonly locale: string = "ja"

  readonly dateFormat: string = "YYYY/MM/DD"

  readonly vDatePickerConvertFormat: string = "YYYY-MM-DD"
}
