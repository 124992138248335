import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorEdit]。
 */
export const OperatorEditRouteConfig: RouteConfig = {
  path: "/OperatorEdit",
  name: "OperatorEdit",
  component: () => import("%%/views/operatorEdit/OperatorEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
