import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventHoldDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "HOLD"

  readonly caption: string = "審査結果 - HOLD"

  readonly reason: string = "審査理由"

  readonly memo: string = "審査メモ"

  readonly memoPlaceholder: string = "審査に関するメモを500文字以内で入力してください"

  readonly label: string = "ラベル"

  readonly registrationButton: string = "登録"

  readonly cancelButton: string = "キャンセル"
}
