import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CsvUpdateJa extends AbstractLocaleMessageObject {
  readonly uploadType: string = "指定方法"

  readonly uploadTypeItemScreenEventId: string = "画面で指定（イベントID）"

  readonly uploadTypeItemScreenShopEventId: string = "画面で指定（加盟店管理ID）"

  readonly uploadTypeItemCsv: string = "CSVファイルで指定"

  readonly logicalDelete: string = "論理削除"

  readonly logicalDeleteDo: string = "する"

  readonly logicalDeleteDoNot: string = "しない"

  readonly settleStatus: string = "決済ステータス"

  readonly noChange: string = "変更なし"

  readonly manualAuthoriResult: string = "目視審査結果"

  readonly label: string = "ラベル"

  readonly labelRegister: string = "登録内容"

  readonly labelUnregister: string = "登録解除"

  readonly unregisterCheckbox: string = "登録を解除する"

  readonly csvFile: string = "CSVファイル"

  readonly uploadButton: string = "アップロード"

  readonly confirmationDialogTitle: string = "アップロード確認"

  readonly backButton: string = "戻る"
}
