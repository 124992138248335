/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import { ValidationMessageTemplate, ValidationRuleFunction, ValidationRuleSchema } from "vee-validate/dist/types/types"

import { ipAddressV4Validator } from "./ipAddressV4Validator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール ipAddressV4 の定義です。
 * 許容する形式は、10進数オクテット/区切りでネットマスクビット数（CIDR形式）です。
 */
export class IpAddressV4RuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return ipAddressV4Validator(value, params)
  }
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params)
  }
}
