export function delimiterItemAmpersandValidator(value: any, params: any[] | Record<string, any>) {
  const item1Name = (params as { [key: string]: string }).item1Name
  const item1Max = (params as { [key: string]: string }).item1Max
  const item2Name = (params as { [key: string]: string }).item2Name
  const item2Max = (params as { [key: string]: string }).item2Max
  const item1CheckHash = (params as { [key: string]: string }).item1CheckHash === "true"
  const item2CheckHash = (params as { [key: string]: string }).item2CheckHash === "true"
  let valid = true
  const items = value.split(/\r\n|\n/)
  for (const item of items) {
    const matchArray = item.match(/&/g)
    if (!matchArray || matchArray.length > 1) {
      valid = false
      break
    }
    const splitArray = item.trim().split(/&/)
    if (splitArray.length !== 2) {
      valid = false
      break
    } else {
      if (item1CheckHash && !splitArray[0].match(/^[0-9a-fA-F]{64}$/)) {
        valid = false
        break
      }
      if (item2CheckHash && !splitArray[1].match(/^[0-9a-fA-F]{64}$/)) {
        valid = false
        break
      }
      if (splitArray[0].length > item1Max || splitArray[1].length > item2Max) {
        valid = false
        break
      }
    }
  }
  return valid
}
