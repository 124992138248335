import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"

import store from ".."

@Module({
  dynamic: true,
  store,
  name: "CommonOverlayModule",
  namespaced: true,
})
class CommonOverlayModule extends VuexModule {
  private fOverlayState: boolean = false

  /**
   * オーバーレイ表示
   */
  get overlayState(): boolean {
    return this.fOverlayState
  }

  @Mutation
  private updateOverlayState(showOverlay: boolean): void {
    this.fOverlayState = showOverlay
  }

  @Action({ commit: "updateOverlayState" })
  changeOverlay(showOverlay: boolean) {
    return showOverlay
  }
}

/**
 * ModuleをObject化 => コンポーネントでimportする
 */
export const commonOverlayModule = getModule(CommonOverlayModule)
