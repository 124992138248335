import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IWhiteList } from "%%/labels/interfaces"

export class WhiteListEn extends AbstractLocaleMessageObject implements IWhiteList {
  readonly createDate = "Date"
  readonly type = "Type"
  readonly unSelected = "Unspecified"
  readonly ipAddress = "IP Address"
  readonly customersBuyerId = "Shop Member ID"
  readonly tel = "Phone Number"
  readonly value = "Value"
  readonly valuePlaceholder = "Only single input allowed, search by prefix match."
  readonly createdAt = "Created Date and Time"
  readonly createdBy = "Operator Name"
  readonly searchButton = "Search"
  readonly deleteButton = "Delete"
  readonly deleteTableName = "White Information"
  readonly toWhiteAddButton = "White Registration(Without Detection)"
  readonly notFoundMessage = "No relevant data found"
}
