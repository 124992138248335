import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { INegativeHistory } from "%%/labels/interfaces"

export class NegativeHistoryEn extends AbstractLocaleMessageObject implements INegativeHistory {
  readonly createdAt = "Registration Date"

  readonly createdAtFrom = "Date"

  readonly createdAtFromPlaceholder = "Start Date Placeholder"

  readonly createdAtTo = "Date"

  readonly createdAtToPlaceholder = "End Date Placeholder"

  readonly datetimeRange = "to"

  readonly searchButton = "Search"

  readonly tableHeaderUploadBy = "Registration Trigger"

  readonly tableHeaderCreatedAt = "Upload Date and Time"

  readonly tableHeaderTimeFinish = "Completion Date"

  readonly tableHeaderRegistCount = "Count"

  readonly tableHeaderCreatedBy = "Registered User"

  readonly tableHeaderStatus = "Status"

  readonly tableHeaderUploadFile = "Upload File"

  readonly tableHeaderResultFile = "Result File"

  readonly tableOperationDownloadButton = "Download"

  readonly empty = "-"

  readonly backButton = "Back to List"
}
