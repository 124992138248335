import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonDateTimeSelectionFieldEn extends AbstractLocaleMessageObject {
  readonly defaultDatePlaceholder = "YYYY/MM/DD"

  readonly locale = "ja"

  readonly dateFormat = "YYYY/MM/DD"

  readonly vDatePickerConvertFormat = "YYYY-MM-DD"

  readonly defaultHourPlaceholder = "Hour"

  readonly defaultMinutePlaceholder = "Minute"
}
