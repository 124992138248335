import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { INegativeAdd } from "%%/labels/interfaces"

export class NegativeAddEn extends AbstractLocaleMessageObject implements INegativeAdd {
  readonly quickRegistrationSubtitle = "Add by Specifying Items"

  readonly quickRegistrationItem = "Registration Item"

  readonly quickRegistrationItemNormalizedName = "Normalized Name"

  readonly quickRegistrationItemTel = "Landline Phone Number"

  readonly quickRegistrationItemMobileTel = "Mobile Phone Number"

  readonly quickRegistrationItemFullAddress = "Address"

  readonly quickRegistrationItemIpaddress = "IP Address"

  readonly quickRegistrationItemCookie = "Cookie"

  readonly quickRegistrationItemMail = "PC Email Address"

  readonly quickRegistrationItemMobileMail = "Mobile Email Address"

  readonly quickRegistrationValue = "Registration Item"

  readonly quickRegistrationValuePlaceholderName =
    "Please enter the last name (SHA-256 hash, 64 characters fixed) and first name (SHA-256 hash, 64 characters fixed) connected with &."

  readonly normalizedLastName = "Last Name"

  readonly normalizedFirstName = "First Name"

  readonly mailAccount = "PC Email Account"

  readonly mailDomain = "PC Email Domain"

  readonly mobileMailAccount = "Mobile Email Account"

  readonly mobileMailDomain = "Mobile Email Domain"

  readonly settleCategory = "Negative\nSettlement Category"

  readonly otherCategory = "Negative\nOther Category"

  readonly categoryUnselected = " "

  readonly quickRegistrationButton = "Register"

  readonly registrationTableName = "Negative Information"

  readonly csvRegistrationSubtitle = "Add from CSV File"

  readonly csvRegistrationRequiredMessage = "This is a required field"

  readonly csvRegistrationButton = "Upload"

  readonly downloadSampleFile = "Click here for sample file"

  readonly backButton = "Back to List"

  readonly illegalExtensionMessage = "Invalid file extension"

  readonly noUpdateItemSelectedMessage = "No item selected for update. Please select at least one."

  readonly validationMessageLimit = "characters or less"

  readonly validationMessageHash = "(SHA-256 hash, 64 characters fixed)"

  readonly quickRegistrationValuePlaceholderTel =
    "For multiple entries, please enter on separate lines (Max 20 characters per line)."

  readonly quickRegistrationValuePlaceholderFulladdress =
    "For multiple entries, please enter on separate lines (Max 200 characters per line)."

  readonly quickRegistrationValuePlaceholderIpaddress =
    "For multiple entries, please enter on separate lines (Max 50 characters per line)."

  readonly quickRegistrationValuePlaceholderCookie =
    "For multiple entries, please enter on separate lines (Max 500 characters per line)."

  readonly quickRegistrationValuePlaceholderMail =
    "Please enter the email account (SHA256 hash, 64 characters fixed) and email domain connected with &."
}
