import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICsvUpdate } from "%%/labels/interfaces"

export class CsvUpdateEn extends AbstractLocaleMessageObject implements ICsvUpdate {
  readonly uploadType = "Selection Method"

  readonly uploadTypeItemScreenEventId = "Specify on Screen (Event ID)"

  readonly uploadTypeItemScreenShopEventId = "Specify on Screen (Shop Event ID)"

  readonly uploadTypeItemCsv = "Specify via CSV File"

  readonly logicalDelete = "Logical Delete"

  readonly logicalDeleteDo = "Execute"

  readonly logicalDeleteDoNot = "Do Not Execute"

  readonly settleStatus = "Settlement Status"

  readonly noChange = "No Change"

  readonly manualAuthoriResult = "Manual Detection Result"

  readonly label = "Label"

  readonly labelRegister = "Register Content"

  readonly labelUnregister = "Unregister"

  readonly unregisterCheckbox = "Unregister"

  readonly csvFile = "CSV File"

  readonly uploadButton = "Upload"

  readonly confirmationDialogTitle = "Upload Confirmation"

  readonly backButton = "Back"
}
