export function delimiterItemMaxValidator(value: any, params: any[] | Record<string, any>) {
  const max = (params as { [key: string]: string }).max
  const delimiter = (params as { [key: string]: string }).delimiter
  let valid = true
  if (delimiter === "line") {
    const items = value.split(/\r\n|\n/)
    for (const item of items) {
      if (item.length > max) {
        valid = false
        break
      }
    }
  } else if (delimiter === "space") {
    const items = value.split(/\s+/)
    for (const item of items) {
      if (item.length > max) {
        valid = false
        break
      }
    }
  }
  return valid
}
