/**
 * IPアドレスV4のバリデーション
 *  許容する形式は、10進数オクテット。
 * @param value
 * @param params
 */
export function ipAddressV4Validator(value: any, params: any[] | Record<string, any>) {
  if (
    /^(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))((\.(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))){3})$/.test(
      value
    )
  ) {
    return true
  }
  return false
}
