/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import { ValidationMessageTemplate, ValidationRuleFunction, ValidationRuleSchema } from "vee-validate/dist/types/types"

import { customEmailValidator } from "./customEmailValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール customEmail の定義です。
 * ・すべて半角文字で‘@’と’.’が含まれる
 * ・トップドメイン(最後の.以降の文字)は半角英字のみ
 * ・他は記号の入力可能(バックスラッシュ、シングルクォーテーション、ダブルクォーテーション、バッククォーテーション、タブコード、キャリッジリターン、ラインフィード、 カンマを除く）
 * *RFCの観点ではシングルクォーテーション、バッククォーテーションは一応許容できるが、通常は利用されないものなので除外する。
 * ・@と.の前後は1文字以上必須
 */
export class CustomEmailRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return customEmailValidator(value, params)
  }
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params)
  }
}
