import { RequestHeader } from "@/oplux/v3/api/common/valueobject/RequestHeader"

/**
 * 通信に関するメタ情報
 */
export class ScreenApiRequestHeader extends RequestHeader {
  /**
   * 認証キー
   */
  token?: string = ""

  /**
   * 加盟店ID
   */
  shopId: string = ""
}
