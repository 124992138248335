/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import {
  RuleParamSchema,
  ValidationMessageTemplate,
  ValidationRuleFunction,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types"

import { delimiterItemMaxValidator } from "./delimiterItemMaxValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール delimiterItemMaxの定義です。
 */
export class DelimiterItemMaxRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return delimiterItemMaxValidator(value, params)
  }
  params: RuleParamSchema[] = ["max", "delimiter"]
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params, ["max", "delimiter"])
  }
}
