import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventCsvDownloadDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "CSV出力"

  readonly caption: string = "イベント一覧CSV出力"

  readonly delimiter: string = "区切り文字"

  readonly delimiterComma: string = "カンマ"

  readonly delimiterTab: string = "タブ"

  readonly outputItem: string = "出力項目"

  readonly outputItemAll: string = "全項目を選択"

  readonly outputItemBasic: string = "基本情報"

  readonly outputItemBasicEventId: string = "イベントID"

  readonly outputItemBasicCompanyId: string = "加盟店ID"

  readonly outputItemBasicShopEventId: string = "加盟店管理ID"

  readonly outputItemBasicAuthoriModelId: string = "審査モデルID"

  readonly outputItemBasicRulePackageId: string = "ルールパッケージID"

  readonly outputItemBasicAuthKind: string = "審査種別"

  readonly outputItemBasicAuthStatus: string = "審査ステータス"

  readonly outputItemBasicDeadline: string = "目視審査締切日時"

  readonly outputItemBasicAssigneeName: string = "審査担当者"

  readonly outputItemBasicTotalAmount: string = "決済金額合計"

  readonly outputItemBasicLimitPrice: string = "上限金額"

  readonly outputItemBasicRuleGroupName: string = "判定結果グループ名"

  readonly outputItemBasicAaresult: string = "自動審査結果"

  readonly outputItemBasicMaresult: string = "目視審査結果"

  readonly outputItemBasicSettleStatus: string = "決済ステータス"

  readonly outputItemBasicCreatedAt: string = "自動審査日時"

  readonly outputItemBasicAmount: string = "決済金額"

  readonly outputItemBasicMethod: string = "決済方法"

  readonly outputItemBasicDaysToPayment: string = "入金までの日数"

  readonly outputItemBasicTenantName: string = "店子"

  readonly outputItemBasicNegatived: string = "ネガティブ登録"

  readonly outputItemBasicLabelName: string = "ラベル情報"

  readonly outputItemPurchaser: string = "購入者情報"

  readonly outputItemPurchaserShopMemberId: string = "購入者会員ID"

  readonly outputItemPurchaserLastName: string = "苗字"

  readonly outputItemPurchaserFirstName: string = "名前"

  readonly outputItemPurchaserZipcode: string = "郵便番号"

  readonly outputItemPurchaserFullAddress: string = "住所"

  readonly outputItemPurchaserTel: string = "固定電話番号"

  readonly outputItemPurchaserTelStatus: string = "固定疎通"

  readonly outputItemPurchaserMobileTel: string = "携帯電話番号"

  readonly outputItemPurchaserMobileTelStatus: string = "携帯疎通"

  readonly outputItemPurchaserMailAccount: string = "PCメールアカウント"

  readonly outputItemPurchaserMailDomain: string = "PCメールドメイン"

  readonly outputItemPurchaserMobileMailAccount: string = "携帯メールアカウント"

  readonly outputItemPurchaserMobileMailDomain: string = "携帯メールドメイン"

  readonly outputItemPurchaserBirthdate: string = "生年月日"

  readonly outputItemPurchaserSex: string = "性別"

  readonly outputItemPurchaserCompany: string = "会社名"

  readonly outputItemPurchaserDepart: string = "部署名"

  readonly outputItemPurchaserPost: string = "役職"

  readonly outputItemPurchaserExistingCustomerFlg: string = "顧客分類"

  readonly outputItemShipping: string = "配送先情報"

  readonly outputItemShippingShopMemberId: string = "配送先会員ID"

  readonly outputItemShippingLastName: string = "苗字"

  readonly outputItemShippingFirstName: string = "名前"

  readonly outputItemShippingZipcode: string = "郵便番号"

  readonly outputItemShippingFullAddress: string = "住所"

  readonly outputItemShippingTel: string = "固定電話番号"

  readonly outputItemShippingTelStatus: string = "固定疎通"

  readonly outputItemShippingMobileTel: string = "携帯電話番号"

  readonly outputItemShippingMobileTelStatus: string = "携帯疎通"

  readonly outputItemShippingMailAccount: string = "PCメールアカウント"

  readonly outputItemShippingMailDomain: string = "PCメールドメイン"

  readonly outputItemShippingMobileMailAccount: string = "携帯メールアカウント"

  readonly outputItemShippingMobileMailDomain: string = "携帯メールドメイン"

  readonly outputItemShippingDeliveryCompany: string = "配送業者"

  readonly outputItemItem: string = "注文情報"

  readonly outputItemItemShopItemId: string = "加盟店商品ID"

  readonly outputItemItemItemName: string = "商品名"

  readonly outputItemItemPrice: string = "単価"

  readonly outputItemItemQuantity: string = "数量"

  readonly outputItemItemCategory: string = "商品カテゴリ"

  readonly outputItemItemStock: string = "在庫有無"

  readonly outputItemDevice: string = "端末等情報"

  readonly outputItemDeviceCookie: string = "Cookie"

  readonly outputItemDevicePrimaryDid: string = "デバイスID"

  readonly outputItemDeviceIpaddress: string = "IPアドレス"

  readonly outputItemDeviceDomainName: string = "IPドメイン"

  readonly outputItemDeviceLineJName: string = "回線種別"

  readonly outputItemDeviceCountryJName: string = "IP国情報"

  readonly outputItemDevicePrefecturesCityJName: string = "IP地域情報"

  readonly outputItemDeviceCityLatitudeLongitude: string = "緯度/経度"

  readonly outputItemDeviceTimeZone: string = "タイムゾーン"

  readonly outputItemDeviceDeviceType: string = "デバイス種別"

  readonly outputItemDeviceUseragent: string = "ユーザーエージェント文字列"

  readonly outputItemDeviceBrowserLanguage: string = "言語設定"

  readonly outputItemDeviceAuthoriId: string = "O-motion審査ID"

  readonly outputItemDeviceResult: string = "O-motion審査結果"

  readonly outputItemDeviceUserDeviceId: string = "端末ID"

  readonly outputItemDeviceDidShort: string = "DID short"

  readonly outputItemDeviceDidMiddle: string = "DID middle"

  readonly outputItemDeviceCountryAName: string = "国名（英語表記）"

  readonly outputItemDevicePrefAName: string = "都道県名（英語表記）"

  readonly outputItemCard: string = "カード情報"

  readonly outputItemCardIssuer: string = "カード発行元"

  readonly outputItemCardCardBrand: string = "カードブランド"

  readonly outputItemCardBincode: string = "BIN"

  readonly outputItemCardCchash: string = "カード番号ハッシュ"

  readonly outputItemCardExpirationDate: string = "有効期限"

  readonly outputItemCardAuthoriCode: string = "オーソリ結果コード"

  readonly outputItemCardAuthoriComment: string = "オーソリ結果コメント"

  readonly outputItemCardPaymentCount: string = "支払回数"

  readonly outputItemOther: string = "その他"

  readonly outputItemOtherMemo: string = "メモ"

  readonly outputItemFiredRule: string = "発動ルール"

  readonly outputItemFiredRuleCode: string = "ルールコード"

  readonly outputItemFiredRuleDescription: string = "ルール説明"

  readonly outputItemCausedRuleCode: string = "審査理由のルールコード"

  readonly outputItemCausedRuleDescription: string = "審査理由のルール説明"

  readonly outputItemRelatedEvent: string = "関連イベント"

  readonly outputItemRelatedEventId: string = "関連イベントID"

  readonly outputItemRelatedEventShopEventId: string = "関連加盟店管理ID"

  readonly confirmationDialogTitle: string = "出力確認"

  readonly outputButton: string = "出力"

  readonly cancelButton: string = "キャンセル"
}
