import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [EventList]。
 */
export const EventListRouteConfig: RouteConfig = {
  path: "/EventList",
  name: "EventList",
  component: () => import("%%/views/eventList/EventList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
