import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventNgDlg } from "%%/labels/interfaces"

export class EventNgDlgEn extends AbstractLocaleMessageObject implements IEventNgDlg {
  readonly showDialogButton = "NG"

  readonly caption = "Detection Result - NG"

  readonly reason = "Detection Reason"

  readonly memo = "Review Memo"

  readonly memoPlaceholder = "Please enter a memo regarding the review within 500 characters"

  readonly label = "Label"

  readonly purchaser = "Purchaser"

  readonly operationTypeItemNothing = "Do Nothing"

  readonly operationTypeItemRegistration = "Register as Negative"

  readonly operationTypeItemRelease = "Release Negative"

  readonly shipping = "Shipping Address"

  readonly settleCategory = "Settlement Category"

  readonly otherCategory = "Other Category"

  readonly registrationButton = "Register"

  readonly cancelButton = "Cancel"
}
