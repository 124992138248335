import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICommonAllSelectionParts } from "%%/labels/interfaces"

export class CommonAllSelectionPartsEn extends AbstractLocaleMessageObject implements ICommonAllSelectionParts {
  readonly resultSelectedCount = "Count: {1} out of {0} selected"

  readonly allSelection = "All items ({0}) are selected"

  readonly allSelectionButton = "Select all search results"

  readonly allDeselectionButton = "Deselect all items"

  readonly totalCount = "Total count: {0}"
}
