import { ValidationMessageTemplate, ValidationRuleFunction, ValidationRuleSchema } from "vee-validate/dist/types/types"

import { uniqueIpAddressValidator } from "./uniqueIpAddressValidator"
import { customMessage } from "./validatorUtil"

export class UniqueIpAddressRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (ipAddress, params) => {
    const [subnet, ...ipAddresses] = params as Array<string>
    return uniqueIpAddressValidator(`${ipAddress}/${subnet}`, ipAddresses)
  }
  message: ValidationMessageTemplate = (ipAddress, params) => {
    return customMessage(ipAddress, params)
  }
}
