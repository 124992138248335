import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventItemDisplayFormatEdit } from "%%/labels/interfaces"

export class EventItemDisplayFormatEditEn extends AbstractLocaleMessageObject implements IEventItemDisplayFormatEdit {
  readonly hide = "Hide"

  readonly upButton = "↑"

  readonly downButton = "↓"

  readonly rightButton = "→"

  readonly leftButton = "←"

  readonly fixedDisplayButton = "Fixed\nDisplay"

  // ボタンからはみ出ないように、日本語版より1つ多く改行
  readonly fixedDisplayExclusionButton = "Fixed\nDisplay\nExclusion"

  readonly updateButton = "Update"

  readonly cancelButton = "Cancel"

  readonly eventProperty = "Event Information"

  readonly aaresult = "Automatic Detection Result"

  readonly createdAt = "Automatic Detection Date and Time"

  readonly authStatus = "Detection Status"

  readonly maresult = "Manual Detection Result"

  readonly deadline = "Manual Detection Deadline"

  readonly assigneeName = "Reviewer Name"

  readonly totalAmount = "Total Payment Amount"

  readonly limitPrice = "Limit Price"

  readonly ruleGroupName = "Judgment Group Name"

  readonly itemCategories = "Target/Related Events"

  readonly itemCategory = "Main Category"

  readonly basic = "Basic Information"

  readonly purchaser = "Purchaser Information"

  readonly shippings = "Shipping Information"

  readonly items = "Order Information"

  readonly device = "Device Information"

  readonly card = "Card Information"

  readonly other = "Other"

  readonly fixedItems = "Fixed Display Items"

  readonly shopEventId = "Merchant Management ID"

  readonly authoriModelId = "Review Model ID"

  readonly rulePackageId = "Rule Package ID"

  readonly settleStatus = "Payment Status"

  readonly amount = "Payment Amount"

  readonly method = "Payment Method"

  readonly daysToPayment = "Days to Payment"

  readonly tenantName = "Tenant"

  readonly negatived = "Negative Information"

  readonly labelNames = "Label Information"

  readonly requestedAt = "Detection Application Date and Time"

  readonly shopMemberId = "Member ID"

  readonly purchaserString = "Purchaser"

  readonly shippingString = "Shipping Address"

  readonly lastName = "Last Name"

  readonly firstName = "First Name"

  readonly zipcode = "Postal Code"

  readonly fullAddress = "Address"

  readonly tel = "Landline Phone Number"

  readonly telStatus = "Landline Communication"

  readonly mobileTel = "Mobile Phone Number"

  readonly mobileTelStatus = "Mobile Communication"

  readonly mailAccount = "PC Email Address"

  readonly mailDomain = "PC Email Domain"

  readonly mobileMailAccount = "Mobile Email Account"

  readonly mobileMailDomain = "Mobile Email Domain"

  readonly birthdate = "Birthday"

  readonly sex = "Gender"

  readonly company = "Company Name"

  readonly depart = "Department"

  readonly post = "Position"

  readonly existingCustomerFlg = "Existing Customer"

  readonly mediaCode = "Media Code"

  readonly deliveryCompany = "Delivery Company"

  readonly shopItemId = "Shop Item ID"

  readonly itemName = "Item Name"

  readonly price = "Price"

  readonly quantity = "Quantity"

  readonly category = "Item Category"

  readonly stock = "Stock Availability"

  readonly cookie = "Cookie"

  readonly primaryDid = "Device ID"

  readonly ipaddress = "IP Address"

  readonly domainName = "IP Domain"

  readonly lineJName = "Line Type"

  readonly countryJName = "IP Country Information"

  readonly prefCityJName = "IP Regional Information"

  readonly cityLatitudeLongitude = "Latitude/Longitude"

  readonly timeZone = "Time Zone"

  readonly deviceType = "Device Type"

  readonly useragent = "User Agent String"

  readonly browserLanguage = "Language Setting"

  readonly authoriId = "O-motion Review ID"

  readonly result = "O-motion Review Result"

  readonly userDeviceId = "Device ID"

  readonly didShort = "DID Short"

  readonly didMiddle = "DID Middle"

  readonly countryAName = "Country Name (English)"

  readonly prefAName = "Prefecture Name (English)"

  readonly issuer = "Card Issuer"

  readonly cardBrand = "Card Brand"

  readonly bincode = "BIN"

  readonly cchash = "Card Number Hash"

  readonly expirationDate = "Expiration Date"

  readonly authoriCode = "Detection Result Code"

  readonly authoriComment = "Detection Result Comment"

  readonly paymentCount = "Number of Payments"

  readonly memo = "Memo"
}
