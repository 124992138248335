import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class PasswordChangeJa extends AbstractLocaleMessageObject {
  readonly infoPasswordLabel: string = "新パスワード"

  readonly infoPasswordPlaceholder: string = "新しいパスワードを英数混在で8文字以上で入力してください"

  readonly infoConfirmPasswordLabel: string = "新パスワードの確認入力"

  readonly infoConfirmPasswordPlaceholder: string = "もう一度、新パスワードを入力してください"

  readonly operationOkButton: string = "変更"

  readonly operationReturnLoginButton: string = "ログイン画面へ戻る"

  readonly error910: string = "正しいURLではありません。\n再度パスワード変更依頼を行ってください。"

  readonly error919: string = "パスワード変更画面の表示期限が過ぎました。\n再度パスワード変更依頼を行ってください。"

  readonly error921: string = "パスワードの変更に失敗しました。再度実行してください。"
}
