import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventOkDlg } from "%%/labels/interfaces"

export class EventOkDlgEn extends AbstractLocaleMessageObject implements IEventOkDlg {
  readonly showDialogButton = "OK"

  readonly caption = "Detection Result - OK"

  readonly reason = "Detection Reason"

  readonly memo = "Review Memo"

  readonly memoPlaceholder = "Please enter a memo regarding the review within 500 characters"

  readonly label = "Label"

  readonly registrationButton = "Registration"

  readonly cancelButton = "Cancel"
}
