import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonDateTimeSelectionFieldJa extends AbstractLocaleMessageObject {
  readonly defaultDatePlaceholder: string = "YYYY/MM/DD"

  readonly locale: string = "ja"

  readonly dateFormat: string = "YYYY/MM/DD"

  readonly vDatePickerConvertFormat: string = "YYYY-MM-DD"

  readonly defaultHourPlaceholder: string = "時"

  readonly defaultMinutePlaceholder: string = "分"
}
