import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"

import { ScreenPermissionListPostResponse } from "@/oplux/v3/api/screen/ScreenPermissionListPostResponse"

import store from ".."

/**
 * 画面の権限を保持するモジュール
 */
@Module({
  dynamic: true,
  store,
  name: "ScreenPermissionModule",
  namespaced: true,
})
class ScreenPermissionModule extends VuexModule {
  /**
   * 準備が出来たかどうか
   */
  private _prepared: boolean = false

  /**
   * 変更検知用
   */
  private _screenPermissionsChangeToggle = false
  private _screenPermissionsForMenuChangeToggle = false

  /**
   * 画面の権限情報の配列
   */
  private _screenPermissions: { screenId: string; permission: string }[] = []
  private _screenPermissionsForMenu: {
    screenId: string
    permission: string
  }[] = []

  /**
   * ロード時に呼び出します
   */
  init(): void {}

  /**
   * 準備が出来たかどうか
   */
  get isPrepared(): boolean {
    return this._prepared
  }

  /**
   * 変更検知用
   */
  get screenPermissionsChangeToggle(): boolean {
    return this._screenPermissionsChangeToggle
  }
  get screenPermissionsForMenuChangeToggle(): boolean {
    return this._screenPermissionsForMenuChangeToggle
  }

  /**
   * 画面の権限情報へのアクセサ
   */
  get screenPermissions(): { screenId: string; permission: string }[] {
    return this._screenPermissions
  }

  /**
   * メニューの権限情報へのアクセサ
   */
  get screenPermissionsForMenu(): { screenId: string; permission: string }[] {
    return this._screenPermissionsForMenu
  }

  /**
   * リセットする
   */
  @Mutation
  reset(): void {
    this._prepared = false
  }

  /**
   * 画面の権限を更新
   * @param response ScreenPermissionListPostResponse
   */
  @Mutation
  private changeScreenPermissions(response: ScreenPermissionListPostResponse): void {
    this._screenPermissions = response.screenPermissions
    this._screenPermissionsChangeToggle = !this._screenPermissionsChangeToggle
    this._prepared = true
  }

  /**
   * メニューの権限を更新
   * @param response ScreenPermissionListPostResponse
   */
  @Mutation
  private changeScreenPermissionsForMenu(response: ScreenPermissionListPostResponse): void {
    this._screenPermissionsForMenu = response.screenPermissions
    this._screenPermissionsForMenuChangeToggle = !this._screenPermissionsForMenuChangeToggle
  }

  /**
   * API ScreenPermissionList の連携先
   * @param response ScreenPermissionListPostResponse
   */
  @Action({ commit: "changeScreenPermissions" })
  setScreenPermissions(response: ScreenPermissionListPostResponse) {
    return response
  }

  /**
   * API ScreenPermissionList の連携先(Menu用)
   * @param payload
   */
  @Action({ commit: "changeScreenPermissionsForMenu" })
  setScreenPermissionsForMenu(payload: any) {
    return payload.apiTelegram
  }
}

/**
 * ModuleをObject化 => コンポーネントでimportする
 */
export const screenPermissionModule = getModule(ScreenPermissionModule)
