import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IOperatorAdd } from "%%/labels/interfaces"

export class OperatorAddEn extends AbstractLocaleMessageObject implements IOperatorAdd {
  readonly title = "Company Operator Information Registration"

  readonly operatorName = "Operator Name"

  readonly operatorNamePlaceholder = "Please enter within 30 characters"

  readonly companyId = "Company ID"

  readonly roleName = "Role"

  readonly roleNamePlaceholder = "Please select"

  readonly email = "Email Address"

  readonly emailPlaceholder = "Only alphanumeric and symbols are allowed"

  readonly ruleAndAuthoriResultNotifyFlg = "Rule Trigger Notification & Detection Result Notification"

  readonly ruleAndAuthoriResultNotifyStatusTrue = "Receive"

  readonly csvRegisterResultMailFlg = "File Upload Result Email Notification"

  readonly csvRegisterResultMailStatusTrue = "Receive"

  readonly buttonAdd = "Register"

  readonly buttonCancel = "Cancel"

  readonly dialogTitle = "Registration Confirmation"

  readonly tableName = "Company Operator"

  readonly dialogButtonOk = "Yes"

  readonly dialogButtonCancel = "No"
}
