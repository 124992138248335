import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ITopMenuBar } from "%%/labels/interfaces"

export class TopMenuBarEn extends AbstractLocaleMessageObject implements ITopMenuBar {
  readonly defaultAccountName = "Account Name"

  readonly testEnvironment: string = " (Testing Env) "

  readonly logout = "Logout"

  readonly faq: string = "FAQ" // 海外版では現状表示しないがJaの言語ファイルに対応した定義が必要
}
