/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import { configure, extend } from "vee-validate"
import {
  alpha_dash,
  alpha_num,
  between,
  confirmed,
  email,
  integer,
  max,
  min,
  numeric,
  required,
  required_if,
} from "vee-validate/dist/rules"

import { CustomEmailRuleSchema } from "./CustomEmailRuleSchema"
import { DateFormatRuleSchema } from "./DateFormatRuleSchema"
import { DateTimeEndRuleSchema } from "./DateTimeEndRuleSchema"
import { DateTimeSelectionFieldRuleSchema } from "./DateTimeSelectionFieldRuleSchema"
import { DelimiterItemAmpersandRuleSchema } from "./DelimiterItemAmpersandRuleSchema"
import { DelimiterItemMaxRuleSchema } from "./DelimiterItemMaxRuleSchema"
import { DelimiterItemSpaceRuleSchema } from "./DelimiterItemSpaceRuleSchema"
import { DomainRuleSchema } from "./DomainRuleSchema"
import { EmptyIfRuleSchema } from "./EmptyIfRuleSchema"
import { GroupNameDuplicateRuleSchema } from "./GroupNameDuplicateRuleSchema"
import { IpAddressV4RuleSchema } from "./IpAddressV4RuleSchema"
import { IpAddressV4RuleSubnetSchema } from "./IpAddressV4RuleSubnetSchema"
import { MaxValueBigintRuleSchema } from "./MaxValueBigintRuleSchema"
import { PasswordRuleSchema } from "./PasswordRuleSchema"
import { UniqueIpAddressRuleSchema } from "./UniqueIpAddressRuleSchema"
import { validateConfig } from "./validatorUtil"

/**
 * VeeValidateを初期化するためのクラスです。
 */
export class ValidateInitializer {
  /**
   * VeeValidateの初期化を実行します。
   */
  static init(): void {
    configure(validateConfig)

    /* builtin rules */
    extend("between", between)
    extend("alpha_num", alpha_num)
    extend("email", email)
    extend("numeric", numeric)
    extend("integer", integer)
    extend("required", required)
    extend("max", max)
    extend("alpha_dash", alpha_dash)
    extend("min", min)
    extend("confirmed", confirmed)
    extend("required_if", required_if)

    /* custom rules */
    extend("password", new PasswordRuleSchema())
    extend("maxValueBigint", new MaxValueBigintRuleSchema())
    extend("ipAddressV4", new IpAddressV4RuleSchema())
    extend("ipAddressV4Subnet", new IpAddressV4RuleSubnetSchema())
    extend("dateTimeEnd", new DateTimeEndRuleSchema())
    extend("groupNameDuplicate", new GroupNameDuplicateRuleSchema())
    extend("delimiterItemSpace", new DelimiterItemSpaceRuleSchema())
    extend("delimiterItemAmpersand", new DelimiterItemAmpersandRuleSchema())
    extend("delimiterItemMax", new DelimiterItemMaxRuleSchema())
    extend("dateFormat", new DateFormatRuleSchema())
    extend("emptyIf", new EmptyIfRuleSchema())
    extend("customEmail", new CustomEmailRuleSchema())
    extend("domain", new DomainRuleSchema())
    extend("dateTimeSelectionField", new DateTimeSelectionFieldRuleSchema())
    extend("uniqueIpAddress", new UniqueIpAddressRuleSchema())
  }
}
