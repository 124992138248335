/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import {
  RuleParamSchema,
  ValidationMessageTemplate,
  ValidationRuleFunction,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types"

import { emptyIfValidator } from "./emptyIfValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール emptyIf の定義です。
 */
export class EmptyIfRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return emptyIfValidator(value, params)
  }
  params: RuleParamSchema[] = ["emptyIf", "errorMessage"]
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params, ["emptyIf", "errorMessage"])
  }
}
