import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [NegativeList]。
 */
export const NegativeListRouteConfig: RouteConfig = {
  path: "/NegativeList",
  name: "NegativeList",
  component: () => import("%%/views/negativeList/NegativeList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
