import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventNegativeDlg } from "%%/labels/interfaces"

export class EventNegativeDlgEn extends AbstractLocaleMessageObject implements IEventNegativeDlg {
  readonly showDialogButton = "Negative Registration"

  readonly caption = "Negative Registration"

  readonly purchaser = "Purchaser"

  readonly operationTypeItemNothing = "Do Nothing"

  readonly operationTypeItemRegistration = "Register"

  readonly operationTypeItemRelease = "Unregister"

  readonly registered = "Registered\n"

  readonly registeredParenthesis = "({0})"

  readonly registeredDelimiter = ", "

  readonly shipping = "Shipping Address"

  readonly settleCategory = "Settlement Category"

  readonly otherCategory = "Other Category"

  readonly confirmationDialogTitle = "Update Confirmation"

  readonly updateButton = "Update"

  readonly cancelButton = "Cancel"
}
