import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CsvAuthori]。
 */
export const AllowedIpAddressRouteConfig: RouteConfig = {
  path: "/AllowedIpAddress",
  name: "AllowedIpAddress",
  component: () => import("%%/views/allowedIpAddress/AllowedIpAddress.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
