/**
 * メールアドレス文字列バリデーション
 * @param value
 * @param params
 */

export function customEmailValidator(value: any, params: any[] | Record<string, any>) {
  const regex = new RegExp(/^[\w!#$%&*+\-/=?^{|}~. ()<>[\]:;@]+@[\w!#$%&*+\-/=?^{|}~. ()<>[\]:;@]+\.[a-zA-Z]+$/)

  return regex.test(value)
}
