import { RouteConfig } from "vue-router"

import { AllowedIpAddressRouteConfig } from "%%/views/allowedIpAddress/AllowedIpAddressRouteConfig"
import { CsvAuthoriRouteConfig } from "%%/views/csvAuthori/CsvAuthoriRouteConfig"
import { CsvAuthoriHistoryRouteConfig } from "%%/views/csvAuthoriHistory/CsvAuthoriHistoryRouteConfig"
import { CsvNegativeRouteConfig } from "%%/views/csvNegative/CsvNegativeRouteConfig"
import { CsvUpdateRouteConfig } from "%%/views/csvUpdate/CsvUpdateRouteConfig"
import { EventDetailRouteConfig } from "%%/views/eventDetail/EventDetailRouteConfig"
import { EventItemDisplayFormatEditRouteConfig } from "%%/views/eventItemDisplayFormatEdit/EventItemDisplayFormatEditRouteConfig"
import { EventListRouteConfig } from "%%/views/eventList/EventListRouteConfig"
import { EventReportNegativeRouteConfig } from "%%/views/eventReportNegative/EventReportNegativeRouteConfig"
import { LoginInputRouteConfig } from "%%/views/login/LoginInputRouteConfig"
import { NegativeAddRouteConfig } from "%%/views/negativeAdd/NegativeAddRouteConfig"
import { NegativeDetailRouteConfig } from "%%/views/negativeDetail/NegativeDetailRouteConfig"
import { NegativeHistoryRouteConfig } from "%%/views/negativeHistory/NegativeHistoryRouteConfig"
import { NegativeListRouteConfig } from "%%/views/negativeList/NegativeListRouteConfig"
import { OperatorAddRouteConfig } from "%%/views/operatorAdd/OperatorAddRouteConfig"
import { OperatorDetailRouteConfig } from "%%/views/operatorDetail/OperatorDetailRouteConfig"
import { OperatorEditRouteConfig } from "%%/views/operatorEdit/OperatorEditRouteConfig"
import { OperatorListRouteConfig } from "%%/views/operatorList/OperatorListRouteConfig"
import { OperatorRoleListRouteConfig } from "%%/views/operatorRoleList/OperatorRoleListRouteConfig"
import { PasswordChangeRequestRouteConfig } from "%%/views/passwordChange/PasswordChangeRequestRouteConfig"
import { PasswordChangeRouteConfig } from "%%/views/passwordChange/PasswordChangeRouteConfig"
import { WhiteAddRouteConfig } from "%%/views/whiteAdd/WhiteAddRouteConfig"
import { WhiteListRouteConfig } from "%%/views/whiteList/WhiteListRouteConfig"

/**
 * ページコンポーネントをvue-routerでロードするための設定ファイルです
 */
export class RouteSetting {
  /**
   * The Array of RouteConfig.
   */
  static pagedefs: Array<RouteConfig> = [
    OperatorDetailRouteConfig,
    LoginInputRouteConfig,
    { path: "/", redirect: "/login" },
    // 旧ログイン URL でも機能させる設定。ログイン URL 変更通知をしたのち削除する
    { path: "/LoginInput", redirect: "/login" },
    CsvAuthoriHistoryRouteConfig,
    OperatorRoleListRouteConfig,
    OperatorEditRouteConfig,
    NegativeListRouteConfig,
    NegativeDetailRouteConfig,
    CsvAuthoriRouteConfig,
    NegativeHistoryRouteConfig,
    PasswordChangeRequestRouteConfig,
    OperatorAddRouteConfig,
    EventItemDisplayFormatEditRouteConfig,
    CsvNegativeRouteConfig,
    EventDetailRouteConfig,
    PasswordChangeRouteConfig,
    NegativeAddRouteConfig,
    EventListRouteConfig,
    CsvUpdateRouteConfig,
    EventReportNegativeRouteConfig,
    OperatorListRouteConfig,
    AllowedIpAddressRouteConfig,
    WhiteListRouteConfig,
    WhiteAddRouteConfig,
  ]
}
