import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICommonDateSelectionField } from "%%/labels/interfaces"

/**
 * 明確に方針が決まるまで日付フォーマットは日本形式(YYYY/MM/DD)を採用する。
 */
export class CommonDateSelectionFieldEn extends AbstractLocaleMessageObject implements ICommonDateSelectionField {
  readonly defaultPlaceholder = "YYYY/MM/DD"

  readonly locale = "ja"

  readonly dateFormat = "YYYY/MM/DD"

  readonly vDatePickerConvertFormat = "YYYY-MM-DD"
}
