import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"

import { RestoreLoginDataOptions } from "@/common/interfaces/RestoreLoginDataOptions"
import { LoginInfo } from "@/models/persistentEntryPoint/LoginInfo"

import store from ".."

/**
 * LocalStorage保存データ Store Modeule
 */

@Module({
  dynamic: true,
  store,
  name: "PersistentDataModule",
  namespaced: true,
})
class PersistentDataModule extends VuexModule {
  private _inspectionData: LoginInfo = new LoginInfo()
  private _restoreData: LoginInfo = new LoginInfo()
  private _prepared: boolean = false

  public get inspectionentData(): LoginInfo {
    return this._inspectionData
  }

  public get loginData(): LoginInfo {
    return this._restoreData
  }

  /**
   * 準備が出来たかどうか
   */
  get isPrepared(): boolean {
    return this._prepared
  }

  @Mutation
  private changeLoginInfo(loginInfo: LoginInfo): void {
    this._inspectionData = loginInfo
  }

  @Mutation
  private restoreData(data: { loginInfo: LoginInfo; options: RestoreLoginDataOptions }): void {
    if (data.options && data.options.callback) {
      data.options.callback(data.loginInfo)
    } else {
      this._restoreData = data.loginInfo
      this._prepared = true
    }
  }

  /**
   * リセットする
   */
  @Mutation
  reset(): void {
    this._prepared = false
  }

  /**
   * ログイン情報保存
   */
  @Action({ commit: "changeLoginInfo" })
  public setLoginInfo(loginInfo: LoginInfo) {
    return loginInfo
  }

  @Action({ commit: "restoreData" })
  public restoreLoginInfo(payload: { loginInfo: LoginInfo; options: RestoreLoginDataOptions | undefined }) {
    return payload
  }
}

/**
 * ModuleをObject化 => コンポーネントでimportする
 */
export const persistentDataModule = getModule(PersistentDataModule)
