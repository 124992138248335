import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventAssignDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "審査担当者登録"

  readonly caption: string = "担当者登録"

  readonly assignee: string = "審査担当者"

  readonly memo: string = "審査メモ"

  readonly memoPlaceholder: string = "審査に関するメモを500文字以内で入力してください"

  readonly registrationButton: string = "登録"

  readonly cancelButton: string = "キャンセル"
}
