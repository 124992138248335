import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventHoldDlg } from "%%/labels/interfaces"

export class EventHoldDlgEn extends AbstractLocaleMessageObject implements IEventHoldDlg {
  readonly showDialogButton = "HOLD"

  readonly caption = "Detection Result - HOLD"

  readonly reason = "Review Reason"

  readonly memo = "Review Memo"

  readonly memoPlaceholder = "Please enter a memo regarding the review within 500 characters"

  readonly label = "Label"

  readonly registrationButton = "Registration"

  readonly cancelButton = "Cancel"
}
