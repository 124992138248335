import CommunicatorOptions from "@/common/interfaces/CommunicatorOptions"

export type RequestMetaInfo = {
  uri: string
  method: "post" | "put"
  isAuthenticationRequire: boolean
}

export abstract class AbstractRequest {
  /** 継承したクラスの名前を返却する。モック起動の際に ApiClient が使用する */
  abstract telegramId(): string
  abstract requestMetaInfo(): RequestMetaInfo

  /**
   * store actionを文字列で指定する
   * NOTE: getStoreActionId()は現在Communicator.vueでのみ使用されている。
   * Communicator.vueを使ったapi通信を行わない場合、getStoreActionId()を継承先クラスで実装する必要はない
   * @returns "StoreModule/setValue" のような文字列
   */
  getStoreActionId(options?: CommunicatorOptions): string {
    const { uri, method } = this.requestMetaInfo()
    const message = `${uri}(${method})\n\nこのapiではgetStoreActionId()を実装していないため、Communicator.vueで使用できません。`
    throw new Error(message)
  }

  toRuleStudio() {
    return this.requestMetaInfo().uri.startsWith("/api/v3/rule")
  }

  /**
   * string のプロパティをすべてトリムする
   */
  trimString() {
    this.trimStringRecursively(this)
  }

  /**
   * 再帰的にトリムを行う
   */
  private trimStringRecursively(body: Record<string, any>) {
    for (const key in body) {
      if (typeof body[key] === "object") {
        this.trimStringRecursively(body[key])
      } else if (typeof body[key] === "string") {
        body[key] = body[key].trim()
      }
    }
  }
}
