import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventMemoDlg } from "%%/labels/interfaces"

export class EventMemoDlgEn extends AbstractLocaleMessageObject implements IEventMemoDlg {
  readonly showDialogButton = "Review Memo Registration"

  readonly caption = "Memo Registration"

  readonly memo = "Review Memo"

  readonly memoPlaceholder = "Please enter a memo regarding the review within 500 characters"

  readonly registrationButton = "Registration"

  readonly cancelButton = "Cancel"
}
