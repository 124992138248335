import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [NoPage]。
 */
export const NoPageRouteConfig: RouteConfig = {
  path: "/nopage",
  name: "nopage",
  component: () => import("./NoPage.vue"),
  meta: {
    reload: false,
    authRequired: false,
  },
}
