/**
 * max_valueのBigInt版のバリデーション
 */

export function maxValueBigintValidator(value: any, params: any[] | Record<string, any>) {
  const strMax = (params as { [key: string]: string }).max
  if (typeof value !== "string" || 19 < value.length) {
    return false
  }

  const bigIntMax = BigInt(strMax)
  const bigIntValue = BigInt(value)

  if (bigIntMax < bigIntValue) {
    return false
  }
  return true
}
