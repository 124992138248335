import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IPasswordChangeRequest } from "%%/labels/interfaces"

export class PasswordChangeRequestEn extends AbstractLocaleMessageObject implements IPasswordChangeRequest {
  readonly inputLabelCompanyId = "Company ID"

  readonly inputTextBoxCompanyIdPlaceholder = "Up to 50 alphanumeric characters"

  readonly inputLabelMailAddress = "Email Address"

  readonly inputTextBoxMailAddressPlaceholder = "Please enter the email address used for login"

  readonly operationButtonSend = "Send Email"

  readonly operationButtonBack = "Return to Login Screen"
}
