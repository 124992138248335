import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventLabelDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "ラベル更新"

  readonly caption: string = "ラベル更新"

  readonly confirmationDialogTitle: string = "更新確認"

  readonly updateButton: string = "更新"

  readonly cancelButton: string = "キャンセル"
}
