import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [WhiteAdd]。
 */
export const WhiteAddRouteConfig: RouteConfig = {
  path: "/white/new",
  name: "WhiteAdd",
  component: () => import("%%/views/whiteAdd/WhiteAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
