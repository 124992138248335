import { getLoginInfo } from "@/infrastructures"

export function isLogIn() {
  const loginInfo = getLoginInfo()

  return (
    (loginInfo && loginInfo.loginOperator) || // 加盟店向け管理画面のログイン判定
    (loginInfo && loginInfo.loginUser) // かっこ向け管理画面のログイン判定
  )
}

export function isFuseiCheckerContractDiv() {
  const loginInfo = getLoginInfo()

  return (
    loginInfo !== null && // ログイン状態
    loginInfo.loginOperator !== undefined && // 加盟店ユーザー
    loginInfo.loginOperator.contractDiv === "20" // 不正チェッカー
  )
}
