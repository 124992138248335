import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CsvNegativeJa extends AbstractLocaleMessageObject {
  readonly uploadType: string = "指定方法"

  readonly uploadTypeItemEventId: string = "画面で指定（イベントID）"

  readonly uploadTypeItemShopEventId: string = "画面で指定（加盟店管理ID）"

  readonly uploadTypeItemMemberId: string = "画面で指定（会員ID）"

  readonly uploadTypeItemCsv: string = "CSVファイルで指定"

  readonly settleCategory: string = "決済カテゴリ"

  readonly otherCategory: string = "その他カテゴリ"

  readonly noChangeCategory: string = " "

  readonly customerType: string = "登録対象"

  readonly unregister: string = "登録解除"

  readonly unregisterCheckbox: string = "登録を解除する"

  readonly unregisterLabel: string = "※購入者・配送先の両方を解除します"

  readonly csvFile: string = "CSVファイル"

  readonly uploadButton: string = "アップロード"

  readonly noUpdateItemSelectedMessage: string = "更新する項目が選択されていません。いずれかを選択する必要があります。"

  readonly backButton: string = "一覧に戻る"
}
