import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventNegativeDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "ネガティブ登録"

  readonly caption: string = "ネガティブ登録"

  readonly purchaser: string = "購入者"

  readonly operationTypeItemNothing: string = "何もしない"

  readonly operationTypeItemRegistration: string = "登録する"

  readonly operationTypeItemRelease: string = "登録解除"

  readonly registered: string = "登録済み\n"

  readonly registeredParenthesis: string = "（{0}）"

  readonly registeredDelimiter: string = "、"

  readonly shipping: string = "配送先"

  readonly settleCategory: string = "決済カテゴリ"

  readonly otherCategory: string = "その他カテゴリ"

  readonly confirmationDialogTitle: string = "更新確認"

  readonly updateButton: string = "更新"

  readonly cancelButton: string = "キャンセル"
}
