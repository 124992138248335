/**
 * Snackbar (toast) に関連づけられるデータを表すクラスです。
 */
export class SnackbarAttribute {
  /**
   * snackbarの色です。&quot;success&quot;, &quot;info&quot;, &quot;error&quot; などを指定します。
   */
  color: string = "info"

  /**
   * multi-line または vertical を指定します。
   */
  mode: string = ""

  /**
   * snackbarを表示する場合はtrueを指定します。
   */
  snackbar: boolean = false

  /**
   * snackbarに表示するテキストをしていします。
   */
  text: string = "delicious!"

  /**
   * snackbarが自動的に消えるまでの時間（ミリ秒）
   */
  timeout: number = 6000

  /**
   * snackbarの水平方向配置。&quot;left&quot;, &quot;right&quot; 以外の場合に中央寄せとなります。
   */
  x: string = "center"

  /**
   * snackbarの垂直方向配置。&quot;top&quot;, &quot;bottom&quot;が指定できます。
   */
  y: string = "top"

  /**
   * 複数メッセージを区別するためにuuidを付与します。
   */
  id: string = ""
}
