/**
 * パスワード文字列バリデーション
 *  英大文字・小文字・数字・記号から3種類以上使用されていない場合,FALSE
 * @param value
 * @param params
 */

export function passwordValidator(value: any, params: any[] | Record<string, any>) {
  //const format = (params as { [key: string]: string }).format;
  // 使用可能記号リスト
  const PASSWORD_SYMBOL = "@%+/\\\\'!#$^?:.(){}\\[\\]\\~\\-_"
  //const regexAll = new RegExp(/^[a-zA-Z0-9@%+/\\'!#$^?:.(){}\[\]\~\-_]+$/);
  const regexAll = new RegExp(`^[a-zA-Z0-9${PASSWORD_SYMBOL}]+$`)
  const regexAlphaUpper = new RegExp(/[A-Z]/)
  const regexAlphaLower = new RegExp(/[a-z]/)
  const regexNumber = new RegExp(/[0-9]/)
  const regexSymbol = new RegExp(`[${PASSWORD_SYMBOL}]`)
  let typeCount: number = 0

  // 使用可能文字判定
  if (regexAll.test(value)) {
    // 文字種ごとの利用チェック
    if (regexAlphaUpper.test(value)) {
      typeCount++
    }
    if (regexAlphaLower.test(value)) {
      typeCount++
    }
    if (regexNumber.test(value)) {
      typeCount++
    }
    if (regexSymbol.test(value)) {
      typeCount++
    }
    if (typeCount < 3) {
      return false
    }
  } else {
    return false
  }
  //return moment(value, format, true).isValid();
  return true
}
