import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IOperatorEdit } from "%%/labels/interfaces"

export class OperatorEditEn extends AbstractLocaleMessageObject implements IOperatorEdit {
  readonly title = "Company Operator Information Edit"

  readonly operatorId = "Operator ID"

  readonly operatorName = "Operator Name"

  readonly operatorNamePlaceholder = "Please enter within 30 characters"

  readonly companyId = "Company ID"

  readonly roleName = "Role"

  readonly email = "Email Address"

  readonly emailPlaceholder = "Only alphanumeric and symbols are allowed"

  readonly ruleAndAuthoriResultNotifyFlg = "Rule Activation & Detection Results Notification"

  readonly ruleAndAuthoriResultNotifyStatusTrue = "Receive"

  readonly csvRegisterResultMailFlg = "File Upload Result Email Notification"

  readonly csvRegisterResultMailStatusTrue = "Receive"

  readonly passwordLock = "Account Status"

  readonly passwordLockTrue = "Locked"

  readonly passwordLockFalse = " - "

  readonly buttonEdit = "Update"

  readonly buttonDelete = "Delete"

  readonly buttonCancel = "Cancel"

  readonly dialogEditTitle = "Edit Confirmation"

  readonly tableName = "Company Operator"

  readonly dialogButtonOk = "Yes"

  readonly dialogButtonCancel = "No"

  readonly dialogDeleteTitle = "Delete Confirmation"
}
