/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import {
  RuleParamSchema,
  ValidationMessageTemplate,
  ValidationRuleFunction,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types"

import { dateTimeSelectionFieldValidator } from "./dateTimeSelectionFieldValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール dateTimeSelectionField の定義です。
 * いずれかの対象がtrueの場合に自身を必須とします。
 */
export class DateTimeSelectionFieldRuleSchema implements ValidationRuleSchema {
  computesRequired: boolean = true
  validate: ValidationRuleFunction = (value, params) => {
    return dateTimeSelectionFieldValidator(value, params)
  }
  params: RuleParamSchema[] = ["target1", "target2"]
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params, ["target1", "target2"])
  }
}
