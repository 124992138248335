import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CsvUpdate]。
 */
export const CsvUpdateRouteConfig: RouteConfig = {
  path: "/CsvUpdate",
  name: "CsvUpdate",
  component: () => import("%%/views/csvUpdate/CsvUpdate.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
