import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorEditJa extends AbstractLocaleMessageObject {
  readonly title: string = "加盟店オペレータ情報編集"

  readonly operatorId: string = "オペレータID"

  readonly operatorName: string = "オペレータ名"

  readonly operatorNamePlaceholder: string = "30文字以内で入力して下さい"

  readonly companyId: string = "加盟店ID"

  readonly roleName: string = "ロール"

  readonly email: string = "メールアドレス"

  readonly emailPlaceholder: string = "半角英数字記号のみ使用できます"

  readonly ruleAndAuthoriResultNotifyFlg: string = "ルール発動通知・審査結果通知"

  readonly ruleAndAuthoriResultNotifyStatusTrue: string = "受信する"

  readonly csvRegisterResultMailFlg: string = "ファイルアップロード結果メール通知"

  readonly csvRegisterResultMailStatusTrue: string = "受信する"

  readonly passwordLock: string = "アカウントの状態"

  readonly passwordLockTrue: string = "ロック中"

  readonly passwordLockFalse: string = " - "

  readonly buttonEdit: string = "更新"

  readonly buttonDelete: string = "削除"

  readonly buttonCancel: string = "キャンセル"

  readonly dialogEditTitle: string = "編集 確認"

  readonly tableName: string = "加盟店オペレータ"

  readonly dialogButtonOk: string = "はい"

  readonly dialogButtonCancel: string = "いいえ"

  readonly dialogDeleteTitle: string = "削除 確認"
}
