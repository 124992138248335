import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IOperatorDetail } from "%%/labels/interfaces"

export class OperatorDetailEn extends AbstractLocaleMessageObject implements IOperatorDetail {
  readonly title = "Company Operator Information Details"

  readonly operatorId = "Operator ID"

  readonly operatorName = "Operator Name"

  readonly companyId = "Company ID"

  readonly roleId = "Role ID"

  readonly roleName = "Role"

  readonly email = "Email Address"

  readonly ruleAndAuthoriResultNotifyFlg = "Rule Activation & Review Results Notification"

  readonly ruleAndAuthoriResultNotifyStatusTrue = "Receive"

  readonly csvRegisterResultMailFlg = "File Upload Result Email Notification"

  readonly csvRegisterResultMailStatusTrue = "Receive"

  readonly passwordLock = "Account Status"

  readonly passwordLockTrue = "Locked"

  readonly passwordLockFalse = " - "

  readonly buttonEdit = "Edit"

  readonly buttonBack = "Back to Company Operator List"
}
