import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class TrialProgressJa extends AbstractLocaleMessageObject {
  readonly inTrialDuration: string = "トライアル期間中"

  readonly trialEventCount: string = "審査 ({currentEventTrialCount} / {eventTrialCountLimit} 件)"

  readonly trialDuration: string = "期間 ({trialStartDatetime} 〜 {trialEndDatetime})"

  readonly trialDurationNotStarted: string = "期間 (トライアル審査を開始していません)"
}
