/*
 * このソースコードは blanco Frameworkによって自動生成されています。
 */
import CommunicatorOptions from "@/common/interfaces/CommunicatorOptions"
import { AbstractRequest, RequestMetaInfo } from "@/oplux/v3/api/common/AbstractRequest"

/**
 * かっこロール情報一覧取得APIの要求電文です。
 */
export class ScreenPermissionListPostRequest extends AbstractRequest {
  getStoreActionId(options?: CommunicatorOptions): string {
    if (options && options.extraArg === "menu") {
      return "ScreenPermissionModule/setScreenPermissionsForMenu"
    }
    return "ScreenPermissionModule/setScreenPermissions"
  }

  requestMetaInfo(): RequestMetaInfo {
    return {
      uri: "/api/v3/screen/screenPermissionList",
      method: "post",
      isAuthenticationRequire: true,
    }
  }

  /**
   * 問い合わせる画面IDの配列
   *
   * 規定値   [new Array&lt;string&gt;()]
   */
  screenIds?: Array<string> = new Array<string>()

  static genericScreenIds(): string | undefined {
    return "string"
  }

  /**
   * この電文クラス名を文字列で返します。
   *
   * @return この電文のクラス名です
   */
  telegramId(): string {
    return "ScreenPermissionListPostRequest"
  }
}
