/**
 * IPアドレスV4のサブネットバリデーション
 *  許容する形式は、0~32。
 * @param value
 * @param params
 */
export function ipAddressV4SubnetValidator(value: any, params: any[] | Record<string, any>) {
  if (/^(([0-9])|([12][0-9])|(3[0-2]))$/.test(value)) {
    return true
  }
  return false
}
