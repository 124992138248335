/**
 * フック登録用
 * main.ts にて最上位で import することで、全体の Component でフック設定が有効となる。
 */
import Component from "vue-class-component"

/**
 * コンポーネントのフック登録
 */
Component.registerHooks(["metaInfo"])
