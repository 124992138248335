import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventCsvDownloadDlg } from "%%/labels/interfaces"

export class EventCsvDownloadDlgEn extends AbstractLocaleMessageObject implements IEventCsvDownloadDlg {
  readonly showDialogButton = "CSV Output"

  readonly caption = "Event List CSV Output"

  readonly delimiter = "Delimiter"

  readonly delimiterComma = "Comma"

  readonly delimiterTab = "Tab"

  readonly outputItem = "Output Items"

  readonly outputItemAll = "Select All Items"

  readonly outputItemBasic = "Basic Information"

  readonly outputItemBasicEventId = "Event ID"

  readonly outputItemBasicCompanyId = "Company ID"

  readonly outputItemBasicShopEventId = "Shop Event ID"

  readonly outputItemBasicAuthoriModelId = "Detection Model ID"

  readonly outputItemBasicRulePackageId = "Rule Package ID"

  readonly outputItemBasicAuthKind = "Detection Type"

  readonly outputItemBasicAuthStatus = "Detection Status"

  readonly outputItemBasicDeadline = "Manual Detection Deadline"

  readonly outputItemBasicAssigneeName = "Reviewer Name"

  readonly outputItemBasicTotalAmount = "Total Settlement Amount"

  readonly outputItemBasicLimitPrice = "Upper Limit Amount"

  readonly outputItemBasicRuleGroupName = "Judgement Result Group Name"

  readonly outputItemBasicAaresult = "Automatic Detection Result"

  readonly outputItemBasicMaresult = "Manual Detection Result"

  readonly outputItemBasicSettleStatus = "Settlement Status"

  readonly outputItemBasicCreatedAt = "Automatic Detection Date and Time"

  readonly outputItemBasicAmount = "Settlement Amount"

  readonly outputItemBasicMethod = "Settlement Method"

  readonly outputItemBasicDaysToPayment = "Days to Payment"

  readonly outputItemBasicTenantName = "Tenant"

  readonly outputItemBasicNegatived = "Negative Registration"

  readonly outputItemBasicLabelName = "Label Information"

  readonly outputItemPurchaser = "Purchaser Information"

  readonly outputItemPurchaserShopMemberId = "Purchaser Member ID"

  readonly outputItemPurchaserLastName = "Last Name"

  readonly outputItemPurchaserFirstName = "First Name"

  readonly outputItemPurchaserZipcode = "Zip Code"

  readonly outputItemPurchaserFullAddress = "Address"

  readonly outputItemPurchaserTel = "Telephone Number"

  readonly outputItemPurchaserTelStatus = "Telephone Connectivity"

  readonly outputItemPurchaserMobileTel = "Mobile Phone Number"

  readonly outputItemPurchaserMobileTelStatus = "Mobile Connectivity"

  readonly outputItemPurchaserMailAccount = "PC Email Account"

  readonly outputItemPurchaserMailDomain = "PC Email Domain"

  readonly outputItemPurchaserMobileMailAccount = "Mobile Email Account"

  readonly outputItemPurchaserMobileMailDomain = "Mobile Email Domain"

  readonly outputItemPurchaserBirthdate = "Birthday"

  readonly outputItemPurchaserSex = "Gender"

  readonly outputItemPurchaserCompany = "Company Name"

  readonly outputItemPurchaserDepart = "Department Name"

  readonly outputItemPurchaserPost = "Position"

  readonly outputItemPurchaserExistingCustomerFlg = "Purchaser Classification"

  readonly outputItemShipping = "Shipping Information"

  readonly outputItemShippingShopMemberId = "Shipping Member ID"

  readonly outputItemShippingLastName = "Last Name"

  readonly outputItemShippingFirstName = "First Name"

  readonly outputItemShippingZipcode = "Zip Code"

  readonly outputItemShippingFullAddress = "Address"

  readonly outputItemShippingTel = "Telephone Number"

  readonly outputItemShippingTelStatus = "Telephone Connectivity"

  readonly outputItemShippingMobileTel = "Mobile Phone Number"

  readonly outputItemShippingMobileTelStatus = "Mobile Connectivity"

  readonly outputItemShippingMailAccount = "PC Email Account"

  readonly outputItemShippingMailDomain = "PC Email Domain"

  readonly outputItemShippingMobileMailAccount = "Mobile Email Account"

  readonly outputItemShippingMobileMailDomain = "Mobile Email Domain"

  readonly outputItemShippingDeliveryCompany = "Delivery Company"

  readonly outputItemItem = "Order Information"

  readonly outputItemItemShopItemId = "Shop Item ID"

  readonly outputItemItemItemName = "Item Name"

  readonly outputItemItemPrice = "Unit Price"

  readonly outputItemItemQuantity = "Quantity"

  readonly outputItemItemCategory = "Item Category"

  readonly outputItemItemStock = "Stock Availability"

  readonly outputItemDevice = "Device Information"

  readonly outputItemDeviceCookie = "Cookie"

  readonly outputItemDevicePrimaryDid = "Device ID"

  readonly outputItemDeviceIpaddress = "IP Address"

  readonly outputItemDeviceDomainName = "IP Domain"

  readonly outputItemDeviceLineJName = "Line Type"

  readonly outputItemDeviceCountryJName = "IP Country Information"

  readonly outputItemDevicePrefecturesCityJName = "IP Region Information"

  readonly outputItemDeviceCityLatitudeLongitude = "Latitude/Longitude"

  readonly outputItemDeviceTimeZone = "Time Zone"

  readonly outputItemDeviceDeviceType = "Device Type"

  readonly outputItemDeviceUseragent = "User Agent String"

  readonly outputItemDeviceBrowserLanguage = "Language Setting"

  readonly outputItemDeviceAuthoriId = "O-motion Review ID"

  readonly outputItemDeviceResult = "O-motion Review Result"

  readonly outputItemDeviceUserDeviceId = "Device ID"

  readonly outputItemDeviceDidShort = "DID Short"

  readonly outputItemDeviceDidMiddle = "DID Middle"

  readonly outputItemDeviceCountryAName = "Country Name (English)"

  readonly outputItemDevicePrefAName = "Prefecture Name (English)"

  readonly outputItemCard = "Card Information"

  readonly outputItemCardIssuer = "Card Issuer"

  readonly outputItemCardCardBrand = "Card Brand"

  readonly outputItemCardBincode = "BIN"

  readonly outputItemCardCchash = "Card Number Hash"

  readonly outputItemCardExpirationDate = "Expiration Date"

  readonly outputItemCardAuthoriCode = "Detection Result Code"

  readonly outputItemCardAuthoriComment = "Detection Result Comment"

  readonly outputItemCardPaymentCount = "Payment Count"

  readonly outputItemOther = "Other"

  readonly outputItemOtherMemo = "Memo"

  readonly outputItemFiredRule = "Triggered Rule"

  readonly outputItemFiredRuleCode = "Rule Code"

  readonly outputItemFiredRuleDescription = "Rule Description"

  readonly outputItemCausedRuleCode: string = "Rule Code That Caused Automatic Detection Result"

  readonly outputItemCausedRuleDescription: string = "Rule Description That Caused Automatic Detection Result"

  readonly outputItemRelatedEvent = "Related Event"

  readonly outputItemRelatedEventId = "Related Event ID"

  readonly outputItemRelatedEventShopEventId = "Related Shop Event ID"

  readonly confirmationDialogTitle = "Output Confirmation"

  readonly outputButton = "Output"

  readonly cancelButton = "Cancel"
}
