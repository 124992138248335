import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonConfirmDialogJa extends AbstractLocaleMessageObject {
  readonly buttonAdd: string = "登録"

  readonly buttonUpdate: string = "更新"

  readonly buttonEdit: string = "編集"

  readonly buttonDelete: string = "削除"

  readonly buttonBack: string = "キャンセル"

  readonly confirmDialogButtonOk: string = "実行"

  readonly confirmDialogButtonCancel: string = "キャンセル"

  readonly defaultTitle: string = "確認"

  readonly defaultTitleAdd: string = "登録確認"

  readonly defaultTitleEdit: string = "編集確認"

  readonly defaultTitleDelete: string = "削除確認"
}
