import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [LoginInput]。
 */
export const LoginInputRouteConfig: RouteConfig = {
  path: "/login",
  name: "LoginInput",
  component: () => import("%%/views/login/LoginInput.vue"),
  meta: {
    reload: true,
    authRequired: false,
  },
}
