/**
 * commonDateTimeSelectionField用バリデーション
 * @param value
 * @param params
 */

export function dateTimeSelectionFieldValidator(value: any, params: any[] | Record<string, any>) {
  const target1 = (params as { [key: string]: string }).target1
  const target2 = (params as { [key: string]: string }).target2

  if (!target1 && !target2) {
    return true
  }

  if (value === undefined || value === null || String(value).trim().length === 0) {
    return false
  }
  return true
}
