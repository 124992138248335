import { AbstractResponse } from "@/oplux/v3/api/common/AbstractResponse"
import { CommonResponse } from "@/oplux/v3/api/common/valueobject/CommonResponse"
import { ErrorItem } from "@/oplux/v3/api/common/valueobject/ErrorItem"
import { MessageItem } from "@/oplux/v3/api/common/valueobject/MessageItem"

export class ApiResult<T extends AbstractResponse = AbstractResponse> {
  readonly apiTelegram: T | undefined

  constructor(
    readonly commonResponse: CommonResponse<T> | undefined,
    readonly messages: MessageItem[],
    readonly errors: ErrorItem[]
  ) {
    if (errors.length !== 0 || !commonResponse) {
      this.apiTelegram = undefined
    } else {
      this.apiTelegram = commonResponse.telegram
    }
  }

  hasMessages(): boolean {
    return this.messages.length > 0
  }

  hasErrors(): boolean {
    return this.errors.length > 0
  }
}
