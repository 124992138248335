import Vue from "vue"
import Vuetify from "vuetify"
import colors from "vuetify/es5/util/colors"

Vue.use(Vuetify)

// マテリアルコンポーネントライブラリ有効化
export default new Vuetify({
  // 利用するアイコンを指定（Material Design Icons を指定）
  icons: {
    iconfont: "mdi",
  },
  // Vuetify のテーマカラー設定
  // 指定可能な値は、ここを参照 https://vuetifyjs.com/ja/framework/colors
  theme: {
    themes: {
      light: {
        primary: colors.lightBlue.darken2,
        secondary: colors.lightBlue.lighten4,
        accent: colors.indigo.base,
      },
    },
  },
})
