import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICsvNegative } from "%%/labels/interfaces"

export class CsvNegativeEn extends AbstractLocaleMessageObject implements ICsvNegative {
  readonly uploadType = "Selection Method"

  readonly uploadTypeItemEventId = "Specify on Screen (Event ID)"

  readonly uploadTypeItemShopEventId = "Specify on Screen (Shop Event ID)"

  readonly uploadTypeItemMemberId = "Specify on Screen (Member ID)"

  readonly uploadTypeItemCsv = "Specify via CSV File"

  readonly settleCategory = "Settlement Category"

  readonly otherCategory = "Other Category"

  readonly noChangeCategory = " "

  readonly customerType = "Registration Target"

  readonly unregister = "Unregister"

  readonly unregisterCheckbox = "Unregister"

  readonly unregisterLabel = "※Both purchaser and shipping address will be removed"

  readonly csvFile = "CSV File"

  readonly uploadButton = "Upload"

  readonly noUpdateItemSelectedMessage = "No item selected for update. You need to select at least one."

  readonly backButton = "Back to List"
}
