import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorDetail]。
 */
export const OperatorDetailRouteConfig: RouteConfig = {
  path: "/OperatorDetail",
  name: "OperatorDetail",
  component: () => import("%%/views/operatorDetail/OperatorDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
