import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class NegativeDetailJa extends AbstractLocaleMessageObject {
  readonly createdAt: string = "登録日時"

  readonly addType: string = "登録種別"

  readonly eventId: string = "イベントID"

  readonly customerType: string = "カスタマ種別"

  readonly shopMemberId: string = "加盟店会員ID"

  readonly normalizedLastName: string = "苗字"

  readonly normalizedFirstName: string = "名前"

  readonly zipcode: string = "郵便番号"

  readonly fullAddress: string = "住所"

  readonly birthDate: string = "生年月日"

  readonly tel: string = "固定電話番号"

  readonly mobileTel: string = "携帯電話番号"

  readonly mailAccount: string = "PCメールアカウント"

  readonly mailDomain: string = "PCメールドメイン"

  readonly mobileMailAccount: string = "携帯メールアカウント"

  readonly mobileMailDomain: string = "携帯メールドメイン"

  readonly company: string = "勤務先名"

  readonly depart: string = "部署"

  readonly cookie: string = "Cookie"

  readonly settleCategory: string = "ネガティブ決済カテゴリ"

  readonly otherCategory: string = "ネガティブその他カテゴリ"

  readonly ipaddress: string = "IPアドレス"

  readonly ipaddressv6: string = "IPアドレスv6"

  readonly deviceType: string = "デバイスタイプ"

  readonly primaryDid: string = "デバイスID"

  readonly didMiddle: string = "DID middle"

  readonly didShort: string = "DID short"

  readonly etag: string = "Etag"

  readonly localStorage: string = "Local Storage"

  readonly shopTenantId: string = "店子ID"

  readonly tenantName: string = "店子名"

  readonly backButton: string = "一覧に戻る"

  readonly deleteButton: string = "削除"

  readonly deleteTableName: string = "ネガティブ情報"

  readonly empty: string = "－"

  readonly updatedAt: string = "更新日時"

  readonly shopEventId: string = "加盟店管理ID"
}
