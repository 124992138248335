import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class WhiteAddJa extends AbstractLocaleMessageObject {
  readonly description =
    "1度に <b>{maxInputValue}件</b> まで入力出来ます。<br />登録済みの値と重複する場合は、<b>作成日時</b> と <b>オペレーター名</b> を<b>更新</b>します。<br />"
  readonly type = "種別"
  readonly selectPrompt = "選択して下さい"
  readonly ipAddress = "IPアドレス"
  readonly customersBuyerId = "加盟店会員ID"
  readonly tel = "電話番号"
  readonly value = "値"
  readonly example = "例"
  readonly valuePlaceholderMaxLengthMessage = "（1行当たりの最大文字数：{length}文字）"
  readonly valuePlaceholder = `複数入力する場合は改行して下さい。{maxLengthMessage}
空行はスキップして登録します。（空行のみはエラーになります。）
{example}`
  readonly typeIsRequired = "種別は必須です。"
  readonly valueIsRequired = "値は必須です。"
  readonly maxItemLengthMessage = "登録項目の一つあたりの項目は{maxLength}文字以内にしてください。"
  readonly maxInputMessage = "1度に入力可能な値は{maxInputValue}件までです。"
  readonly addButton = "登録"
  readonly confirmMessage = `ホワイト情報を登録します。
  よろしいですか？
  ※ 件数が多い場合は、時間がかかることがあります。`
  readonly backButton = "一覧に戻る"
}
