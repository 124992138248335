import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CsvAuthoriHistoryJa extends AbstractLocaleMessageObject {
  readonly createdAt: string = "依頼日"

  readonly createdAtFrom: string = "日付"

  readonly createdAtTo: string = "日付"

  readonly datetimeRange: string = "～"

  readonly tableHeaderNo: string = "No"

  readonly tableHeaderUploadType: string = "種別"

  readonly tableHeaderCreatedAt: string = "アップロード日時"

  readonly tableHeaderTimeFinish: string = "処理完了日時"

  readonly tableHeaderCreatedBy: string = "オペレータ名"

  readonly tableHeaderStatus: string = "状態"

  readonly tableHeaderUploadFile: string = "アップロードファイル"

  readonly tableHeaderResultFile: string = "結果ファイル"

  readonly tableOperationDownloadButton: string = "ダウンロード"

  readonly empty: string = "－"

  readonly csvAuthoriButton: string = "CSV審査"

  readonly csvUpdateButton: string = "CSV更新"

  readonly searchButton: string = "検索"
}
