/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import {
  RuleParamSchema,
  ValidationMessageTemplate,
  ValidationRuleFunction,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types"

import { maxValueBigintValidator } from "./maxValueBigintValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * max_value のBigInt版の定義です。
 */
export class MaxValueBigintRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return maxValueBigintValidator(value, params)
  }
  params: RuleParamSchema[] = ["max"]
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params, ["max"])
  }
}
