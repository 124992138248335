import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventDetail } from "%%/labels/interfaces"

export class EventDetailEn extends AbstractLocaleMessageObject implements IEventDetail {
  readonly eventId = "Event ID"

  readonly eventAuthori = "Detection Information"

  readonly eventAuthoriShopEventId = "Shop Event ID"

  readonly eventAuthoriAaresult = "Automatic Detection Result"

  readonly eventAuthoriCreatedAt = "Automatic Detection Date and Time"

  readonly eventAuthoriAuthStatus = "Detection Status"

  readonly eventAuthoriMaresult = "Manual Detection Result"

  readonly eventAuthoriDeadline = "Manual Detection Deadline"

  readonly eventAuthoriAssigneeName = "Detection Assignee"

  readonly eventAuthoriLimitPrice = "Upper Limit Amount"

  readonly eventAuthoriRuleGroupName = "Judgment Result Group Name"

  readonly eventAuthoriTotalAmount = "Total Settlement Amount"

  readonly eventDetail = "Target & Related Events"

  readonly eventDetailRelatedEventRelatedEventId = "Related Event ID"

  readonly eventDetailRelatedEventMaAdditionAmountFlg = "ma_addition_amount_flg"

  readonly eventDetailBasic = "Basic Information"

  readonly eventDetailBasicShopEventId = "Shop Event ID"

  readonly eventDetailBasicAuthoriModelId = "Detection Model ID"

  readonly eventDetailBasicRulePackageId = "Rule Package ID"

  readonly eventDetailBasicAaresult = "Automatic Detection Result"

  readonly eventDetailBasicMaresult = "Manual Detection Result"

  readonly eventDetailBasicSettleStatus = "Settlement Status"

  readonly eventDetailBasicCreatedAt = "Automatic Detection Date and Time"

  readonly eventDetailBasicAmount = "Settlement Amount"

  readonly eventDetailBasicMethod = "Settlement Method"

  readonly eventDetailBasicDaysToPayment = "Days to Payment"

  readonly eventDetailBasicTenantName = "Tenant"

  readonly eventDetailBasicNegatived = "Negative Registration"

  readonly eventDetailBasicLabelNames = "Label Names"

  readonly eventDetailBasicRequestedAt = "Detection Request Date and Time"

  readonly eventDetailPurchaser = "Purchaser Information"

  readonly eventDetailPurchaserShopMemberId = "Purchaser Member ID"

  readonly eventDetailPurchaserLastName = "Last Name"

  readonly eventDetailPurchaserFirstName = "First Name"

  readonly eventDetailPurchaserZipcode = "Zip Code"

  readonly eventDetailPurchaserFullAddress = "Full Address"

  readonly eventDetailPurchaserTel = "Telephone Number"

  readonly eventDetailPurchaserTelStatus = "Telephone Connectivity"

  readonly eventDetailPurchaserMobileTel = "Mobile Phone Number"

  readonly eventDetailPurchaserMobileTelStatus = "Mobile Connectivity"

  readonly eventDetailPurchaserMailAccount = "PC Email Account"

  readonly eventDetailPurchaserMailDomain = "PC Email Domain"

  readonly eventDetailPurchaserMobileMailAccount = "Mobile Email Account"

  readonly eventDetailPurchaserMobileMailDomain = "Mobile Email Domain"

  readonly eventDetailPurchaserBirthdate = "Birthday"

  readonly eventDetailPurchaserSex = "Gender"

  readonly eventDetailPurchaserCompany = "Company Name"

  readonly eventDetailPurchaserDepart = "Department Name"

  readonly eventDetailPurchaserPost = "Position"

  readonly eventDetailPurchaserExistingCustomerFlg = "Purchaser Classification"

  readonly eventDetailPurchaserMediaCode = "Media Code"

  readonly eventDetailShippings = "Shipping Information"

  readonly eventDetailShippingShopMemberId = "Shipping Member ID"

  readonly eventDetailShippingLastName = "Shipping Last Name"

  readonly eventDetailShippingFirstName = "Shipping First Name"

  readonly eventDetailShippingZipcode = "Shipping Zip Code"

  readonly eventDetailShippingFullAddress = "Shipping Address"

  readonly eventDetailShippingTel = "Shipping Telephone Number"

  readonly eventDetailShippingTelStatus = "Shipping Telephone Connectivity"

  readonly eventDetailShippingMobileTel = "Shipping Mobile Phone Number"

  readonly eventDetailShippingMobileTelStatus = "Shipping Mobile Connectivity"

  readonly eventDetailShippingMailAccount = "Shipping PC Email Account"

  readonly eventDetailShippingMailDomain = "Shipping PC Email Domain"

  readonly eventDetailShippingMobileMailAccount = "Shipping Mobile Email Account"

  readonly eventDetailShippingMobileMailDomain = "Shipping Mobile Email Domain"

  readonly eventDetailShippingDeliveryCompany = "Shipping Company"

  readonly eventDetailItems = "Order Information"

  readonly eventDetailItemsItem = "Item"

  readonly eventDetailItemsPrice = "Price"

  readonly eventDetailItemsQuantity = "Quantity"

  readonly eventDetailDevice = "Device Information"

  readonly eventDetailDeviceCookie = "Cookie"

  readonly eventDetailDevicePrimaryDid = "Device ID"

  readonly eventDetailDeviceIpaddress = "IP Address"

  readonly eventDetailDeviceDomainName = "IP Domain"

  readonly eventDetailDeviceLineJName = "Line Type"

  readonly eventDetailDeviceCountryJName = "IP Country Information"

  readonly eventDetailDevicePrefCityJName = "IP Regional Information"

  readonly eventDetailDeviceCityLatitudeLongitude = "Latitude/Longitude"

  readonly eventDetailDeviceTimeZone = "Time Zone"

  readonly eventDetailDeviceDeviceType = "Device Type"

  readonly eventDetailDeviceUseragent = "User Agent String"

  readonly eventDetailDeviceBrowserLanguage = "Browser Language Setting"

  readonly eventDetailDeviceAuthoriId = "O-MOTION Detection ID"

  readonly eventDetailDeviceResult = "O-MOTION Detection Result"

  readonly eventDetailDeviceUserDeviceId = "User Device ID"

  readonly eventDetailDeviceDidShort = "DID Short"

  readonly eventDetailDeviceDidMiddle = "DID Middle"

  readonly eventDetailDeviceCountryAName = "Country Name (English)"

  readonly eventDetailDevicePrefAName = "Prefecture Name (English)"

  readonly eventDetailCard = "Card Information"

  readonly eventDetailCardIssuer = "Card Issuer"

  readonly eventDetailCardCardBrand = "Card Brand"

  readonly eventDetailCardBincode = "BIN"

  readonly eventDetailCardCchash = "Card Number Hash"

  readonly eventDetailCardExpirationDate = "Expiration Date"

  readonly eventDetailCardAuthoriCode = "Detection Result Code"

  readonly eventDetailCardAuthoriComment = "Detection Result Comment"

  readonly eventDetailCardPaymentCount = "Payment Count"

  readonly eventDetailOther = "Other"

  readonly eventDetailOtherMemo = "Memo"

  readonly firedRules = "Triggered Rules Information"

  readonly firedRulesRuleCode = "Rule Code"

  readonly firedRulesRuleDescription = "Rule Description"

  readonly firedRulesRuleGroupName = "Judgment Group Name"

  readonly manualAuthoriHistories = "Manual Detection Result History"

  readonly manualAuthoriHistoriesUpdatedAt = "Update Date and Time"

  readonly manualAuthoriHistoriesResult = "Detection Result"

  readonly manualAuthoriHistoriesReason = "Reason for Manual Detection Result"

  readonly manualAuthoriHistoriesRegistUserName = "Manual Reviewer Name"

  readonly manualAuthoriHistoriesMemo = "Manual Detection Memo"

  readonly btnFireRulesAllShowOpen = "Show All ▼"

  readonly btnFireRulesAllShowClose = "Close ▲"

  readonly btnOk = "APPROVE"

  readonly btnNg = "REJECT"

  readonly btnHold = "HOLD"

  readonly btnUpdateNegative = "Update Negative"

  readonly btnAddAuthoriMemo = "Register Detection Memo"

  readonly btnAddRegistUser = "Register Reviewer Assignee"

  readonly btnUpdateLabel = "Update Label"

  readonly btnCustomizedItems = "Change Display Items"

  readonly btnPrev = "Previous ＜"

  readonly btnNext = "Next ＞"

  readonly btnBackTo = "Back"

  readonly noRelativeData = "No related events"
}
