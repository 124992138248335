/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import {
  RuleParamSchema,
  ValidationMessageTemplate,
  ValidationRuleFunction,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types"

import { delimiterItemSpaceValidator } from "./delimiterItemSpaceValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール delimiterItemSpaceの定義です。
 */
export class DelimiterItemSpaceRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return delimiterItemSpaceValidator(value, params)
  }
  params: RuleParamSchema[] = [
    "item1Name",
    "item1Max",
    "item1CheckHash",
    "item1ValidationMessage",
    "item2Name",
    "item2Max",
    "item2CheckHash",
    "item2ValidationMessage",
  ]
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params, [
      "item1Name",
      "item1Max",
      "item1CheckHash",
      "item1ValidationMessage",
      "item2Name",
      "item2Max",
      "item2CheckHash",
      "item2ValidationMessage",
    ])
  }
}
