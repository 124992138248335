import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CsvAuthoriJa extends AbstractLocaleMessageObject {
  readonly priorityType: string = "審査方法"

  readonly priorityTypeItemSpeed: string = "並列審査（速度優先）"

  readonly priorityTypeItemAccuracy: string = "順次審査（精度優先）"

  readonly csvFile: string = "CSVファイル"

  readonly csvRegistrationRequiredMessage: string = "必須項目です"

  readonly uploadButton: string = "アップロード"

  readonly confirmationDialogTitle: string = "アップロード確認"

  readonly backButton: string = "戻る"

  readonly illegalExtensionMessage: string = "ファイルの拡張子が不正です"
}
