import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICsvAuthori } from "%%/labels/interfaces"

export class CsvAuthoriEn extends AbstractLocaleMessageObject implements ICsvAuthori {
  readonly priorityType = "Detection Method"

  readonly priorityTypeItemSpeed = "Parallel (Speed Priority)"

  readonly priorityTypeItemAccuracy = "Sequential (Accuracy Priority)"

  readonly csvFile = "CSV File"

  readonly csvRegistrationRequiredMessage = "Required Field"

  readonly uploadButton = "Upload"

  readonly confirmationDialogTitle = "Upload Confirmation"

  readonly backButton = "Back"

  readonly illegalExtensionMessage = "Invalid File Extension"
}
