import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class TopMenuBarJa extends AbstractLocaleMessageObject {
  readonly defaultAccountName: string = "アカウント名"

  readonly testEnvironment: string = "（テスト環境）"

  readonly logout: string = "ログアウト"

  readonly faq: string = "よくある質問"
}
