/*
 * Event名の定義です。
 */
/** 画面APIセットを呼び出します */
export const EVENT_CALL_SCREEN_API = "EVENT_CALL_SCREEN_API"
/** ルールスタジオAPIセットを呼び出します */
export const EVENT_CALL_RULESTUDIO_API = "EVENT_CALL_RULESTUDIO_API"
/** TransitDataをリストアするActionを呼び出します */
export const RESTORE_TRANSIT_DATA = "PageTransitDataModule/initialize"
/** TransitDataを削除するActionを呼び出します */
export const CLEAR_TRANSIT_DATA = "PageTransitDataModule/clear"
/** ログイン情報をLocalStorageに保存するActionを呼び出します */
export const PERSISTENT_LOGIN_DATA = "PersistentDataModule/setLoginInfo"
/** ログイン情報をLocalStorageから取得するActionを呼び出します */
export const EVENT_RESTORE_LOGIN_DATA = "EVENT_RESTORE_LOGIN_DATA"
/** Communicator の handleErrors を呼び出す */
export const API_ERROR_HANDRING = "API_ERROR_HANDRING"
