import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ICsvAuthoriHistory } from "%%/labels/interfaces"

export class CsvAuthoriHistoryEn extends AbstractLocaleMessageObject implements ICsvAuthoriHistory {
  readonly createdAt = "Request Date"

  readonly createdAtFrom = "Date"

  readonly createdAtTo = "Date"

  readonly datetimeRange = "to"

  readonly tableHeaderNo = "No"

  readonly tableHeaderUploadType = "Type"

  readonly tableHeaderCreatedAt = "Upload Date"

  readonly tableHeaderTimeFinish = "Completion Date"

  readonly tableHeaderCreatedBy = "Operator Name"

  readonly tableHeaderStatus = "Status"

  readonly tableHeaderUploadFile = "Upload File"

  readonly tableHeaderResultFile = "Result File"

  readonly tableOperationDownloadButton = "Download"

  readonly empty = "-"

  readonly csvAuthoriButton = "CSV Detection"

  readonly csvUpdateButton = "CSV Update"

  readonly searchButton = "Search"
}
