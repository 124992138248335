/*
 * このソースコードはblanco Frameworkによって作成されました。
 */
import {
  RuleParamSchema,
  ValidationMessageTemplate,
  ValidationRuleFunction,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types"

import { dateFormatValidator } from "./dateFormatValidator"
import { customMessage } from "./validatorUtil"

/**
 * VeeValidateのValidateRuleSchemaの実装クラスです。
 *
 * カスタムルール dateFormat の定義です。
 */
export class DateFormatRuleSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return dateFormatValidator(value, params)
  }
  params: RuleParamSchema[] = ["format"]
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params, ["format"])
  }
}
