import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorDetailJa extends AbstractLocaleMessageObject {
  readonly title: string = "加盟店オペレータ情報詳細"

  readonly operatorId: string = "オペレータID"

  readonly operatorName: string = "オペレータ名"

  readonly companyId: string = "加盟店ID"

  readonly roleId: string = "ロールID"

  readonly roleName: string = "ロール"

  readonly email: string = "メールアドレス"

  readonly ruleAndAuthoriResultNotifyFlg: string = "ルール発動通知・審査結果通知"

  readonly ruleAndAuthoriResultNotifyStatusTrue: string = "受信する"

  readonly csvRegisterResultMailFlg: string = "ファイルアップロード結果メール通知"

  readonly csvRegisterResultMailStatusTrue: string = "受信する"

  readonly passwordLock: string = "アカウントの状態"

  readonly passwordLockTrue: string = "ロック中"

  readonly passwordLockFalse: string = " - "

  readonly buttonEdit: string = "編集"

  readonly buttonBack: string = "加盟店オペレータ情報一覧へ戻る"
}
