import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class NegativeHistoryJa extends AbstractLocaleMessageObject {
  readonly createdAt: string = "登録日付"

  readonly createdAtFrom: string = "日付"

  readonly createdAtFromPlaceholder: string = "登録日付開始プレースホルダー"

  readonly createdAtTo: string = "日付"

  readonly createdAtToPlaceholder: string = "登録日付終了プレースホルダー"

  readonly datetimeRange: string = "～"

  readonly searchButton: string = "検索"

  readonly tableHeaderUploadBy: string = "登録契機"

  readonly tableHeaderCreatedAt: string = "アップロード日時"

  readonly tableHeaderTimeFinish: string = "処理完了日時"

  readonly tableHeaderRegistCount: string = "件数"

  readonly tableHeaderCreatedBy: string = "登録ユーザー"

  readonly tableHeaderStatus: string = "ステータス"

  readonly tableHeaderUploadFile: string = "アップロードファイル"

  readonly tableHeaderResultFile: string = "結果ファイル"

  readonly tableOperationDownloadButton: string = "ダウンロード"

  readonly empty: string = "－"

  readonly backButton: string = "一覧に戻る"
}
