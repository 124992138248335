
import { Component, Vue, Watch } from "vue-property-decorator"

import { SessionStorageKey } from "@/common/globals"
import { pageTransitDataModule } from "@/stores/modules/PageTransitDataModule"

@Component
export default class TransitEntryPoint extends Vue {
  created() {}
  render() {}

  get watchData() {
    return pageTransitDataModule.inspectionTransitData
  }

  get watchTransitDataClear() {
    return pageTransitDataModule.transitDataClearToggle
  }

  get toLocation() {
    return pageTransitDataModule.location
  }

  @Watch("watchData")
  nextTo(newData: any, oldData: any) {
    const data = newData
    for (let dataKey in data) {
      if (data[dataKey] === undefined) {
        sessionStorage.removeItem(dataKey)
      } else {
        sessionStorage.setItem(dataKey, JSON.stringify(data[dataKey]))
      }
    }
    if (this.toLocation) {
      this.$router.push(this.toLocation)
    }
  }

  /**
   * 遷移情報の削除
   */
  @Watch("watchTransitDataClear")
  onChangeTransitDataClear() {
    // 削除対象のキーを収集する
    const removeKeys = []
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i)
      if (key != null && SessionStorageKey.RESERVED_KEYS.includes(key) === false) {
        removeKeys.push(key)
      }
    }
    // SessionStorageから削除する
    removeKeys.forEach(key => {
      sessionStorage.removeItem(key)
    })
    // 遷移先があれば遷移する
    if (this.toLocation) {
      this.$router.push(this.toLocation)
    }
  }
}
