/**
 * ドメインのバリデーション
 * 許容するのは、半角英数とハイフンのみ。ラベル（.(ドット）で区切られた文字列）の先頭と末尾にハイフンは使えない。
 * ラベル全体の長さは1文字以上63文字以下。
 * 最低でも1つの「.」が入る、必ず2つ以上のラベルになっていること。
 * @param value
 * @param params
 */
export function domainValidator(value: any, params: any[] | Record<string, any>) {
  // 文字列全体の長さが253文字以下（maxルールでチェック済み）
  // 文字列全体の先頭末尾が、ハイフン又はピリオドの場合はNG
  if (/^[-.].*$|^.*[-.]$/.test(value)) {
    return false
  }
  // 文字列の中に「-.」又は「.-」又は「..」が含まれていたらNG
  if (/^.*-\..*$|^.*\.-.*$|^.*\.\..*$/.test(value)) {
    return false
  }
  // 文字列の中に「.」が最低1つないとNG
  if (/^(?!.*\.).*$/.test(value)) {
    return false
  }
  // .splitしてできた配列の要素の最大値が63文字、最小値が1文字、半角英数字ハイフン
  const items: Array<string> = value.split(".")
  for (const label of items) {
    // ラベルの長さが1文字以上、63文字以下
    if (label.length < 1 || 63 < label.length) {
      return false
    }
    if (/^[0-9a-zA-Z-]+$/.test(label) === false) {
      return false
    }
  }
  return true
}
