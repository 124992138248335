import Vue from "vue"
import VueLazyload from "vue-lazyload"
import VueMeta from "vue-meta"

import store from "@/stores"
import vuetify from "@/vuetify"

import { ValidateInitializer } from "%%/common/validators/ValidateInitializer"
import FrameworkCommon from "%%/components/eventDispatcher/FrameworkCommon.vue"

import i18n from "./i18n"
import router from "./router"

import "@/class-component-hooks"
import "vuetify/dist/vuetify.min.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import "@mdi/font/css/materialdesignicons.min.css"

// VeeValidateを初期化します。
ValidateInitializer.init()

Vue.config.productionTip = false

// HTML メタデータ設定用ライブラリ有効化
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

// 画像遅延読込有効化
// img タグに v-lazy="foo.png" などで src を設定する必要あり。
Vue.use(VueLazyload, {
  // 事前読込をする高さの割合
  preLoad: 1.3,
  // エラー時の画像
  error: "@/assets/images/error.png",
  // 読み込み中の画像
  loading: "@/assets/images/loading.png",
  // リトライ上限
  attempt: 1,
  // デバッグメッセージ非表示
  silent: true,
})

/// <reference path = "@/common/globals.ts" />
export const vueInstance = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: createElement => {
    return createElement(FrameworkCommon)
  },
}).$mount("#app")

const appEl = document.querySelector("#app")
if (appEl && process.env.VUE_APP_IS_E2E === "true") {
  appEl.classList.add("is-e2e")
  require("@/style/only-e2e.scss")
}
