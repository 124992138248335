import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventReportNegativeJa extends AbstractLocaleMessageObject {
  readonly aggregateClass: string = "集計区分"

  readonly monthly: string = "月次"

  readonly daily: string = "日次"

  readonly authoriModel: string = "審査モデル"

  readonly all: string = "全て"

  readonly csvOutputLink: string = "CSV出力"

  readonly leftTableHeader: string = "自動審査結果レポート"

  readonly aaresultTotal: string = "自動審査結果件数"

  readonly aaresultOk: string = "自動審査結果OK"

  readonly aaresultNg: string = "自動審査結果NG"

  readonly aaresultHold: string = "自動審査結果HOLD"

  readonly aaresultReview: string = "自動審査結果REVIEW"

  readonly negativedNo: string = "ネガティブ登録無し"

  readonly negativedYes: string = "ネガティブ登録有り"

  readonly csvFilenameDaily: string = "日次レポートCSVファイル"

  readonly csvFilenameMonthly: string = "月次レポートCSVファイル"
}
