import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventLabelDlg } from "%%/labels/interfaces"

export class EventLabelDlgEn extends AbstractLocaleMessageObject implements IEventLabelDlg {
  readonly showDialogButton = "Update Label"

  readonly caption = "Update Label"

  readonly confirmationDialogTitle = "Update Confirmation"

  readonly updateButton = "Update"

  readonly cancelButton = "Cancel"
}
