import Vue from "vue"
import Router, { Route } from "vue-router"

import { isLogIn } from "@/services"

import { CommonRouteSetting } from "%%/views/CommonRouteSetting"
import { LoginInputRouteConfig } from "%%/views/login/LoginInputRouteConfig"
import { NoPageRouteConfig } from "%%/views/nopage/NoPageRouteConfig"
import { RouteSetting } from "%%/views/RouteSetting"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: CommonRouteSetting.pagedefs.concat(RouteSetting.pagedefs),
})

function hasPage(router: Router, to: Route) {
  return router.resolve(to.path).resolved.name !== undefined
}

router.beforeEach((to, _, next) => {
  if (!hasPage(router, to)) {
    next(NoPageRouteConfig.path)
    // /login -> /login -> /login ... の無限ループを防ぐために遷移先がログインパスでないことを確認
  } else if (to.meta && to.meta.authRequired && !isLogIn() && to.path != LoginInputRouteConfig.path) {
    next(LoginInputRouteConfig.path)
  } else {
    next()
  }
})

export default router
