import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonMessageJa extends AbstractLocaleMessageObject {
  readonly error999 =
    "審査中です。結果をお待ちください。\n審査状況を確認したい場合は、「CSV一括審査登録・更新履歴」画面をご覧ください。"
  readonly error1000 =
    "更新中です。結果をお待ちください。\n更新状況を確認したい場合は、「CSV一括審査登録・更新履歴」画面をご覧ください。"

  readonly info686: string = "ログアウトします。\nよろしいですか？"

  readonly error920: string = "新パスワード、新パスワードの確認入力の入力値が一致しません。"

  readonly info677: string = "パスワード変更が正常終了しました。ログインに戻ってください。"

  readonly info691: string = "パスワードを変更します。\nよろしいですか？"

  readonly info692: string =
    "新しいパスワードを入力してください。\n＜パスワード条件＞\n・半角英数字８～20文字\n・次のうち3つ以上含める ※英大文字、英小文字、数字、特殊文字\n＜使用可能な特殊文字＞\n@ % + / ＼ ‘ ! # $ ^ ? : . ( ) { } [ ] ~ _ -"

  readonly error902: string = "更新する項目が選択されていません。いずれかを選択する必要があります。"

  readonly info693: string =
    "パスワード変更依頼を受け付けました。\n指定されたメールアドレスにパスワード変更画面へのURLが記載されたメールが送信されますので、ご確認ください。"

  readonly info674: string = "オペレータ確認のため、加盟店IDとメールアドレスを入力してください。"

  readonly info667: string = "審査モデル情報を複写します。\nよろしいですか？"

  readonly info675: string =
    "複数の審査モデルのイベントが選択されています。\n単一の審査モデルのイベントを選択してください。"

  readonly info689: string = "イベントを{0}削除します。\nよろしいですか？"

  readonly info678: string = "目視審査結果をHOLDで登録します。\nよろしいですか？"

  readonly info679: string = "目視審査結果をNGで登録します。\nよろしいですか？"

  readonly info680: string = "目視審査結果をOKで登録します。\nよろしいですか？"

  readonly info684: string = "審査担当者を登録します。\nよろしいですか？"

  readonly info685: string = "審査メモを登録します。\nよろしいですか？"

  readonly info681: string = "CSVファイルを出力します。\nよろしいですか？"

  readonly info687: string = "CSV出力依頼を受け付けました。ファイル作成完了後、ダウンロード用URLをメールで送信します。"

  readonly info683: string = "ネガティブ情報を更新します。\nよろしいですか？"

  readonly error914: string = "ラベルの登録がありません。"

  readonly info673: string = "本番運用中O-PLUX管理画面にログインしました。"

  readonly info695: string = "選択したデータをCSVファイルに出力します。出力ファイルはメールにて通知されます。"

  readonly info672: string = "リリースが完了しました。"

  readonly info671: string = "有効／無効を切り替えが完了しました。"

  readonly info670: string = "有効／無効を切り替えます。よろしいですか？"

  readonly info669: string = "ステージング環境に自動リリースが完了しました。"

  readonly info668: string = "ステージング環境に自動リリースを実行します。よろしいですか？"

  readonly info696: string =
    "登録が完了しました。\n登録メールアドレスへ、ログインパスワード記載のアカウント登録確認メールを送信いたします。"

  readonly info682: string = "ラベル情報を更新します。\nよろしいですか？"

  readonly error922: string = "更新する項目が選択されていません。いずれかを選択する必要があります。"

  readonly info666: string = "複写が完了しました。"

  readonly error968: string = "ルールとして正しくありません"

  readonly info001: string = "登録が完了しました。"

  readonly error998: string = "システムエラーが発生しました。"

  readonly info002: string = "更新が完了しました。"

  readonly info003: string = "削除が完了しました。"

  readonly info004: string = "ファイルのアップロードが完了しました。"

  readonly info005: string = "ファイルのダウンロードが完了しました。"

  readonly info006: string = "CSVファイルのアップロードを行います。\nよろしいですか？"

  readonly info007: string = "CSVファイルのダウンロードを行います。\nよろしいですか？"

  readonly info008: string = "{0}を登録します。\nよろしいですか？"

  readonly info009: string = "{0}を更新します。\nよろしいですか？"

  readonly info014: string = "{0}を削除します。\nよろしいですか？"

  readonly info011: string =
    "登録が完了しました。\n登録メールアドレスへ、ログインパスワード記載のアカウント登録確認メールを送信いたします。"

  readonly info012: string = "編集中のデータをすべて破棄しました。"

  readonly info013: string =
    "編集中のデータがあります。\n登録する場合は「はい」、編集中のデータをすべて破棄する場合は「いいえ」を押してください。"

  readonly error993: string = "処理中にタイムアウトが発生しました。"

  readonly error991: string =
    "ファイルのアップロードに失敗しました。\n再度実行してください。解決しない場合、管理者へお問い合わせください。"

  readonly error992: string =
    "ファイルのダウンロードに失敗しました。\n再度実行してください。解決しない場合、管理者へお問い合わせください。"

  readonly info676: string = "該当するデータが見つかりませんでした"

  readonly error967: string = "条件式は設定できません。条件式グループを選択してください。"

  readonly info015: string = "他のユーザーが同じ情報を更新しようとしています。"

  readonly info688: string = "イベント「{0}」は他のユーザーが編集中です。"

  readonly error965: string = "システムエラーが発生しました。（965）"

  readonly error964: string = "システムエラーが発生しました。（964）"

  readonly error963: string = "404 Not Found 指定したページはみつかりませんでした。"

  readonly error962: string = "システムエラーが発生しました。（962）"

  readonly error923: string = "グループ名が重複しています。"

  readonly info665: string = "メールアドレスを入力してください。"

  readonly error932: string = "このカテゴリにはパーツが存在します。削除できません。 "

  readonly error933: string = "「シミュレーション」審査種別のルールパッケージだけではリリース情報は作成できません。"

  readonly error934: string = "「通常」審査種別のルールパッケージで開始日時に重複があります。"

  readonly error935: string = "開始日時より後の日時を指定してください。"

  readonly error936: string = "空のグループは登録できません。ユニットを登録するか、空のグループを削除してください"

  readonly error937: string = "空のユニットは登録できません。ルールを登録するか、空のユニットを削除してください"

  readonly info697: string = "データが正しく取得できませんでした。再表示してください。"
}
