import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [WhiteList]。
 */
export const WhiteListRouteConfig: RouteConfig = {
  path: "/white",
  name: "WhiteList",
  component: () => import("%%/views/whiteList/WhiteList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
