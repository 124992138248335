import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AllowedIpAddressJa extends AbstractLocaleMessageObject {
  readonly saveButton = "更新"

  readonly ipAddress = "IPアドレス"

  readonly subnet = "サブネット"

  readonly uiIpAddressInputLabel = "管理画面の閲覧"

  readonly apiIpAddressInputLabel = "API審査の接続元"

  readonly displayedDescription = `
    デフォルトでは、すべてのIPアドレスからのアクセスを許可しています。<br />
    アクセス制限される場合は、アクセスを許可するIPアドレスを設定してください。<br />
  `

  readonly displayedDescriptionLink = "※ご注意"

  readonly detailDescriptionTitle = "操作方法詳細"

  readonly detailDescription = `
    IPアドレスを登録する時は値を入力後に「追加」ボタンをクリックしてリストに追加、<br />
    リストにあるIPアドレスを削除する時は「削除」ボタンをクリックします。<br />
    最後に「更新」ボタンをクリックすると更新が完了します。<br />
    （更新ボタンをクリックしないと編集内容は反映されません）
  `

  readonly detailDescriptionSubnetMask = `
    サブネットマスク（/以降の数値）は「32」がデフォルトです（変更可能）。<br />
    設定値に迷う場合は「32」をおすすめします。
  `

  readonly emptyIpAddresses = "全てのIPアドレスからのアクセスを許可しています。"

  readonly addButton = "追加"

  readonly deleteButton = "削除"

  readonly cancelButton = "元に戻す"

  readonly close = "閉じる"
}
