import { Const } from "@/common/globals"
import { SnackbarAttribute } from "@/models/common/SnackbarAttribute"
import { ErrorItem } from "@/oplux/v3/api/common/valueobject/ErrorItem"
import { MessageItem } from "@/oplux/v3/api/common/valueobject/MessageItem"
import { snackbarModule } from "@/stores/modules/SnackbarModule"

export enum SnackbarType {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

/**
 * Snackbarのユーティリティ
 */
export class SnackbarUtils {
  /**
   * Snackbar呼び出し用のインスタンスを作成する
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param type 表示したいSnackbarのタイプ（SnackbarType.INFOまたはSnackbarType.ERROR）
   * @param text 表示したい文字列
   * @param timeout タイムアウト（オプション）
   */
  static createSnackbarAttribute(type: SnackbarType, text: string, timeout?: number): SnackbarAttribute {
    const snackbarAttribute = new SnackbarAttribute()

    snackbarAttribute.color = type
    snackbarAttribute.text = text
    if (timeout === undefined) {
      switch (type) {
        case SnackbarType.INFO:
          snackbarAttribute.timeout = Const.SnackbarTimeoutInfo
          break
        case SnackbarType.ERROR:
          snackbarAttribute.timeout = Const.SnackbarTimeoutError
          break
        case SnackbarType.SUCCESS:
          snackbarAttribute.timeout = Const.SnackbarTimeoutSuccess
          break
        default:
          throw "Invalid SnackbarType."
      }
    } else {
      snackbarAttribute.timeout = timeout
    }

    return snackbarAttribute
  }

  /**
   * Snackbar即時表示する
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param type 表示したいSnackbarのタイプ（SnackbarType.INFOまたはSnackbarType.ERROR）
   * @param text 表示したい文字列
   * @param timeout タイムアウト（オプション）
   */
  static showSnackbar(type: SnackbarType, text: string, timeout?: number) {
    const snackbarAttribute = this.createSnackbarAttribute(type, text, timeout)

    snackbarModule.showSnackbar(snackbarAttribute)
  }

  /**
   * Snackbar表示をキューイングする（次にTransitViewBaseのmountedが呼ばれたタイミングで表示される）
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param type 表示したいSnackbarのタイプ（SnackbarType.INFOまたはSnackbarType.ERROR）
   * @param text 表示したい文字列
   * @param timeout タイムアウト（オプション）
   */
  static queueingSnackbar(type: SnackbarType, text: string, timeout?: number) {
    const snackbarAttribute = this.createSnackbarAttribute(type, text, timeout)

    snackbarModule.queueingSnackbar(snackbarAttribute)
  }

  /**
   * errorメッセージを表示する
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param errors 表示するErrorItemの配列
   */
  static showErrors(errors: Array<ErrorItem>) {
    errors.forEach((error, errIdx) => {
      // errorのsnackbarを表示する
      let errorMessage = error.code ? error.code : ""
      if (error.message) {
        errorMessage += "\n" + error.message
      }
      this.showSnackbar(SnackbarType.ERROR, errorMessage)
    })
  }

  /**
   * errorメッセージをキューイングする
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param errors 表示するErrorItemの配列
   */
  static queueingErrors(errors: Array<ErrorItem>) {
    errors.forEach((error, errIdx) => {
      // errorのsnackbarを表示する
      let errorMessage = error.code ? error.code : ""
      if (error.message) {
        errorMessage += "\n" + error.message
      }
      this.queueingSnackbar(SnackbarType.ERROR, errorMessage)
    })
  }

  /**
   * infoメッセージを表示する
   * @param component 呼び出し元のコンポーネント（コンポーネント自身から呼び出す場合はthisを指定）
   * @param messages 表示するMessageItemの配列
   */
  static showMessages(messages: Array<MessageItem>) {
    messages.forEach((info, errIdx) => {
      // infoのsnackbarを表示する
      let infoMessage = info.code ? info.code : ""
      if (info.message) {
        infoMessage += "\n" + info.message
      }
      this.showSnackbar(SnackbarType.INFO, infoMessage)
    })
  }

  static showErrorMessages(msgs: Array<string>) {
    msgs.forEach(m => this.showErrorMessage(m))
  }

  static showErrorMessage(msg: string) {
    const snackbarAttribute = this.createSnackbarAttribute(SnackbarType.ERROR, msg)
    snackbarModule.showSnackbar(snackbarAttribute)
  }

  static showInfoMessage(msg: string) {
    const snackbarAttribute = this.createSnackbarAttribute(SnackbarType.INFO, msg)
    snackbarModule.showSnackbar(snackbarAttribute)
  }

  static showSuccessMessage(msg: string) {
    const snackbarAttribute = this.createSnackbarAttribute(SnackbarType.SUCCESS, msg)
    snackbarModule.showSnackbar(snackbarAttribute)
  }
}
