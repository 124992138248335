/**
 * ルールグループ名が重複した場合、バリデーションエラーを表示する。
 * @param value
 * @param params
 */
export function groupNameDuplicateValidator(value: any, params: any[] | Record<string, any>) {
  const groupNameList = (params as { [key: string]: Array<string> }).groupNameList
  const errorMessage = (params as { [key: string]: string }).errorMessage
  let checked = true
  if (groupNameList !== undefined) {
    for (const groupName of groupNameList) {
      if (groupName === value) {
        checked = false
        break
      }
    }
  }
  return checked
}
