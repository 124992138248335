import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { INegativeList } from "%%/labels/interfaces"

export class NegativeListEn extends AbstractLocaleMessageObject implements INegativeList {
  readonly updatedAt = "Update Date"

  readonly updatedAtFrom = "Date"

  readonly updatedAtTo = "Date"

  readonly datetimeRange = "to"

  readonly searchTypeUnselected = "Please Select"

  readonly searchTypeShopMemberId = "Shop Member ID"

  readonly searchTypeNormalizedLastName = "Last Name"

  readonly searchTypeNormalizedFirstName = "First Name"

  readonly searchTypeZipcode = "Postal Code"

  readonly searchTypeFullAddress = "Address"

  readonly searchTypeTel = "Phone Number"

  readonly searchTypeMailAccount = "Email Account"

  readonly searchTypeMailDomain = "Email Domain"

  readonly searchTypeCookie = "Cookie"

  readonly searchTypeSettleCategory = "Negative Settlement Category"

  readonly searchTypeOtherCategory = "Negative Other Category"

  readonly searchTypeIpaddress = "IP Address"

  readonly searchTypeEventId = "Event ID"

  readonly searchTypeShopEventId = "Shop Event ID"

  readonly searchValue = "Search Item Value"

  readonly searchValuePlaceholder = "Separate multiple entries with spaces"

  readonly searchButton = "Search"

  readonly deleteButton = "Delete"

  readonly deleteTableName = "Negative Information"

  readonly csvOutputButton = "CSV Output"

  readonly confirmationDialogTitleOutput = "Output Confirmation"

  readonly negativeHistoryButton = "Negative Registration History"

  readonly tableHeaderUpdatedAt = "Updated Date and Time"

  readonly tableHeaderShopMemberId = "Shop Member ID"

  readonly tableHeaderNormalizedLastName = "Last Name"

  readonly tableHeaderNormalizedFirstName = "First Name"

  readonly tableHeaderZipcode = "Postal Code"

  readonly tableHeaderFullAddress = "Address"

  readonly tableHeaderTel = "Landline Phone Number"

  readonly tableHeaderMobileTel = "Mobile Phone Number"

  readonly tableHeaderMailAccount = "PC Email Account"

  readonly tableHeaderMailDomain = "PC Email Domain"

  readonly tableHeaderMobileMailAccount = "Mobile Email Account"

  readonly tableHeaderMobileMailDomain = "Mobile Email Domain"

  readonly tableHeaderCookie = "Cookie"

  readonly tableHeaderSettleCategory = "Negative Settlement Category"

  readonly tableHeaderOtherCategory = "Negative Other Category"

  readonly tableHeaderIpaddress = "IP Address"

  readonly tableHeaderNegativeId = "Negative ID"

  readonly tableOperationDetailButton = "Details"

  readonly empty = "-"

  readonly negativeAddButton = "Negative Registration (Without Detection)"

  readonly csvNegativeButton = "Negative Registration"
}
