import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class MenuLabelsJa extends AbstractLocaleMessageObject {
  readonly eventList: string = "イベント一覧"

  readonly eventReportNegative: string = "審査結果レポート"

  readonly csvAuthori: string = "CSV一括登録・更新"

  readonly negativeList: string = "ネガティブ登録"

  readonly whiteList: string = "ホワイト登録"

  readonly operator: string = "管理"

  readonly operatorList: string = "オペレータ情報一覧"

  readonly operatorRoleList: string = "ロール情報一覧"

  readonly allowedIpAddress: string = "許可IPアドレス"
}
