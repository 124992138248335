import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class NegativeListJa extends AbstractLocaleMessageObject {
  readonly updatedAt: string = "更新日付"

  readonly updatedAtFrom: string = "日付"

  readonly updatedAtTo: string = "日付"

  readonly datetimeRange: string = "～"

  readonly searchTypeUnselected: string = "選択してください"

  readonly searchTypeShopMemberId: string = "加盟店会員ID"

  readonly searchTypeNormalizedLastName: string = "苗字"

  readonly searchTypeNormalizedFirstName: string = "名前"

  readonly searchTypeZipcode: string = "郵便番号"

  readonly searchTypeFullAddress: string = "住所"

  readonly searchTypeTel: string = "電話番号"

  readonly searchTypeMailAccount: string = "メールアカウント"

  readonly searchTypeMailDomain: string = "メールドメイン"

  readonly searchTypeCookie: string = "Cookie"

  readonly searchTypeSettleCategory: string = "ネガティブ決済カテゴリ"

  readonly searchTypeOtherCategory: string = "ネガティブその他カテゴリ"

  readonly searchTypeIpaddress: string = "IPアドレス"

  readonly searchTypeEventId: string = "イベントID"

  readonly searchTypeShopEventId: string = "加盟店管理ID"

  readonly searchValue: string = "検索項目値"

  readonly searchValuePlaceholder: string = "複数入力はスペースで区切る"

  readonly searchButton: string = "検索"

  readonly deleteButton: string = "削除"

  readonly deleteTableName: string = "ネガティブ情報"

  readonly csvOutputButton: string = "CSV出力"

  readonly confirmationDialogTitleOutput: string = "出力確認"

  readonly negativeHistoryButton: string = "ネガティブ登録履歴"

  readonly tableHeaderUpdatedAt: string = "更新日時"

  readonly tableHeaderShopMemberId: string = "加盟店会員ID"

  readonly tableHeaderNormalizedLastName: string = "苗字"

  readonly tableHeaderNormalizedFirstName: string = "名前"

  readonly tableHeaderZipcode: string = "郵便番号"

  readonly tableHeaderFullAddress: string = "住所"

  readonly tableHeaderTel: string = "固定電話番号"

  readonly tableHeaderMobileTel: string = "携帯電話番号"

  readonly tableHeaderMailAccount: string = "PCメールアカウント"

  readonly tableHeaderMailDomain: string = "PCメールドメイン"

  readonly tableHeaderMobileMailAccount: string = "携帯メールアカウント"

  readonly tableHeaderMobileMailDomain: string = "携帯メールドメイン"

  readonly tableHeaderCookie: string = "Cookie"

  readonly tableHeaderSettleCategory: string = "ネガティブ決済カテゴリ"

  readonly tableHeaderOtherCategory: string = "ネガティブその他カテゴリ"

  readonly tableHeaderIpaddress: string = "IPアドレス"

  readonly tableHeaderNegativeId: string = "ネガティブID"

  readonly tableOperationDetailButton: string = "詳細"

  readonly empty: string = "－"

  readonly negativeAddButton: string = "ネガティブ登録（審査なし）"

  readonly csvNegativeButton: string = "ネガティブ登録"
}
