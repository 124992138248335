import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventMemoDlgJa extends AbstractLocaleMessageObject {
  readonly showDialogButton: string = "審査メモ登録"

  readonly caption: string = "メモ登録"

  readonly memo: string = "審査メモ"

  readonly memoPlaceholder: string = "審査に関するメモを500文字以内で入力してください"

  readonly registrationButton: string = "登録"

  readonly cancelButton: string = "キャンセル"
}
