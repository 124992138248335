import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IWhiteAdd } from "%%/labels/interfaces"

export class WhiteAddEn extends AbstractLocaleMessageObject implements IWhiteAdd {
  readonly description =
    "Up to <b>{maxInputValue}</b> values can be entered.<br />If there is a duplicate with an already registered value, <b>Created Date and Time</b> and <b>Operator Name</b> will be <b>updated</b>.<br />"
  readonly type = "Type"
  readonly selectPrompt = "Please Select"
  readonly ipAddress = "IP Address"
  readonly customersBuyerId = "Shop Member ID"
  readonly tel = "Phone Number"
  readonly value = "Value"
  readonly example = "Example"
  readonly valuePlaceholderMaxLengthMessage = "(Max {length} characters per line)"
  readonly valuePlaceholder = `For multiple entries, please enter on separate lines {maxLengthMessage}.
Blank lines are ignored(Only blank lines will result in an error).
{example}`
  readonly typeIsRequired = "Type is required."
  readonly valueIsRequired = "Value is required."
  readonly maxItemLengthMessage = "Max {maxLength} characters per line."
  readonly maxInputMessage = "Up to {maxInputValue} values can be entered."
  readonly addButton = "Register"
  readonly confirmMessage = `Register white information.
  OK？
  ※ If there are many items, it may take some time.`
  readonly backButton = "Back to List"
}
