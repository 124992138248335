import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IAllowedIpAddress } from "%%/labels/interfaces"

export class AllowedIpAddressEn extends AbstractLocaleMessageObject implements IAllowedIpAddress {
  readonly saveButton = "Update"

  readonly ipAddress = "IP Address"

  readonly subnet = "Subnet"

  readonly uiIpAddressInputLabel = "Admin Panel"

  readonly apiIpAddressInputLabel = "API"

  readonly displayedDescription = `
    By default, access from all IP addresses is allowed.<br />
    If access is restricted, please set the IP addresses that are allowed access.<br />
  `

  readonly displayedDescriptionLink = "*Please note"

  readonly detailDescriptionTitle = "Detailed Operation Method"

  readonly detailDescription = `
    When registering an IP address, enter the value and click the 'Add' button to add it to the list,<br />
    To delete an IP address from the list, click the 'Delete' button.<br />
    Finally, click the 'Update' button to complete the update.<br />
    (If you do not click the update button, the edits will not be reflected)
  `

  readonly detailDescriptionSubnetMask = `
    The default subnet mask (number after '/') is '32' (changeable).<br />
    If you are unsure about the setting value, '32' is recommended.
  `

  readonly emptyIpAddresses = "Access from all IP addresses is allowed."

  readonly addButton = "Add"

  readonly deleteButton = "Delete"

  readonly cancelButton = "Revert"

  readonly close = "Close"
}
