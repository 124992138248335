import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class NegativeAddJa extends AbstractLocaleMessageObject {
  readonly quickRegistrationSubtitle: string = "項目を指定して追加"

  readonly quickRegistrationItem: string = "登録項目"

  readonly quickRegistrationItemNormalizedName: string = "氏名（正規化済み）"

  readonly quickRegistrationItemTel: string = "固定電話番号"

  readonly quickRegistrationItemMobileTel: string = "携帯電話番号"

  readonly quickRegistrationItemFullAddress: string = "住所"

  readonly quickRegistrationItemIpaddress: string = "IPアドレス"

  readonly quickRegistrationItemCookie: string = "Cookie"

  readonly quickRegistrationItemMail: string = "PCメールアドレス"

  readonly quickRegistrationItemMobileMail: string = "携帯メールアドレス"

  readonly quickRegistrationValue: string = "登録項目"

  readonly quickRegistrationValuePlaceholderName: string =
    "苗字(SHA-256ハッシュ形式64文字固定）と名前（SHA-256ハッシュ形式64文字固定）を&で繋いで入力してください。"

  readonly normalizedLastName: string = "苗字"

  readonly normalizedFirstName: string = "名前"

  readonly mailAccount: string = "PCメールアカウン"

  readonly mailDomain: string = "PCメールドメイン"

  readonly mobileMailAccount: string = "携帯メールアカウント"

  readonly mobileMailDomain: string = "携帯メールドメイン"

  readonly settleCategory: string = "ネガティブ\n決済カテゴリ"

  readonly otherCategory: string = "ネガティブ\nその他カテゴリ"

  readonly categoryUnselected: string = " "

  readonly quickRegistrationButton: string = "登録"

  readonly registrationTableName: string = "ネガティブ情報"

  readonly csvRegistrationSubtitle: string = "CSVファイルから追加"

  readonly csvRegistrationRequiredMessage: string = "必須項目です"

  readonly csvRegistrationButton: string = "アップロード"

  readonly downloadSampleFile: string = "サンプルファイルはこちら"

  readonly backButton: string = "一覧に戻る"

  readonly illegalExtensionMessage: string = "ファイルの拡張子が不正です"

  readonly noUpdateItemSelectedMessage: string = "更新する項目が選択されていません。いずれかを選択する必要があります。"

  readonly validationMessageLimit: string = "文字以下"

  readonly validationMessageHash: string = "(SHA-256ハッシュ形式64文字固定）"

  readonly quickRegistrationValuePlaceholderTel: string =
    "複数入力する場合は改行してください（1行あたりの最大文字数：20文字）。"

  readonly quickRegistrationValuePlaceholderFulladdress: string =
    "複数入力する場合は改行してください（1行あたりの最大文字数：200文字）。"

  readonly quickRegistrationValuePlaceholderIpaddress: string =
    "複数入力する場合は改行してください（1行あたりの最大文字数：50文字）。"

  readonly quickRegistrationValuePlaceholderCookie: string =
    "複数入力する場合は改行してください（1行あたりの最大文字数：500文字）。"

  readonly quickRegistrationValuePlaceholderMail: string =
    "メールアカウント（SHA256ハッシュ形式。64文字固定）とメールドメインを&で繋いで入力してください。"
}
