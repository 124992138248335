import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonAllSelectionPartsJa extends AbstractLocaleMessageObject {
  readonly resultSelectedCount: string = "件数：{0}件中 {1}件選択"

  readonly allSelection: string = "全件選択（{0}件）されています。"

  readonly allSelectionButton: string = "検索結果の全てを選択"

  readonly allDeselectionButton: string = "全件選択解除"

  readonly totalCount: string = "件数：{0}件"
}
