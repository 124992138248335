import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { ITrialProgress } from "%%/labels/interfaces"

export class TrialProgressEn extends AbstractLocaleMessageObject implements ITrialProgress {
  readonly inTrialDuration = "In Trial"
  readonly trialEventCount = "Count of Trial Events ({currentEventTrialCount} / {eventTrialCountLimit} )"
  readonly trialDuration = "Duration ({trialStartDatetime} - {trialEndDatetime})"
  readonly trialDurationNotStarted = "Duration (Trial has not been started)"
}
