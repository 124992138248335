import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorRoleListJa extends AbstractLocaleMessageObject {
  readonly index: string = "No"

  readonly roleId: string = "加盟店ロールID"

  readonly roleName: string = "加盟店ロール名"

  readonly functionGroup: string = "利用可能機能"

  readonly editAddLabel: string = "編集・登録"

  readonly can: string = "できる"

  readonly canNot: string = "できない"

  readonly noDataLabel: string = "データはありません。"

  readonly totalCount: string = "件数：{0}件"
}
