import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventItemDisplayFormatEditJa extends AbstractLocaleMessageObject {
  readonly hide: string = "非表示"

  readonly upButton: string = "↑"

  readonly downButton: string = "↓"

  readonly rightButton: string = "→"

  readonly leftButton: string = "←"

  readonly fixedDisplayButton: string = "固定表示"

  readonly fixedDisplayExclusionButton: string = "固定表示\n除外"

  readonly updateButton: string = "更新"

  readonly cancelButton: string = "キャンセル"

  readonly eventProperty: string = "審査情報"

  readonly aaresult: string = "自動審査結果"

  readonly createdAt: string = "自動審査日時"

  readonly authStatus: string = "審査ステータス"

  readonly maresult: string = "目視審査結果"

  readonly deadline: string = "目視審査締切日時"

  readonly assigneeName: string = "審査担当者"

  readonly totalAmount: string = "決済合計金額"

  readonly limitPrice: string = "上限金額"

  readonly ruleGroupName: string = "判定グループ名"

  readonly itemCategories: string = "対象・関連イベント"

  readonly itemCategory: string = "大項目"

  readonly basic: string = "基本情報"

  readonly purchaser: string = "購入者情報"

  readonly shippings: string = "配送先情報"

  readonly items: string = "注文情報"

  readonly device: string = "端末等情報"

  readonly card: string = "カード情報"

  readonly other: string = "その他"

  readonly fixedItems: string = "固定表示項目"

  readonly shopEventId: string = "加盟店管理ID"

  readonly authoriModelId: string = "審査モデルID"

  readonly rulePackageId: string = "ルールパッケージID"

  readonly settleStatus: string = "決済ステータス"

  readonly amount: string = "決済金額"

  readonly method: string = "決済方法"

  readonly daysToPayment: string = "入金までの日数"

  readonly tenantName: string = "店子"

  readonly negatived: string = "ネガティブ情報"

  readonly labelNames: string = "ラベル情報"

  readonly requestedAt: string = "審査申請日時"

  readonly shopMemberId: string = "会員ID"

  readonly purchaserString: string = "購入者"

  readonly shippingString: string = "配送先"

  readonly lastName: string = "苗字"

  readonly firstName: string = "名前"

  readonly zipcode: string = "郵便番号"

  readonly fullAddress: string = "住所"

  readonly tel: string = "固定電話番号"

  readonly telStatus: string = "固定疎通"

  readonly mobileTel: string = "携帯電話番号"

  readonly mobileTelStatus: string = "携帯疎通"

  readonly mailAccount: string = "PCメールアドレス"

  readonly mailDomain: string = "PCメールドメイン"

  readonly mobileMailAccount: string = "携帯メールアカウント"

  readonly mobileMailDomain: string = "携帯メールドメイン"

  readonly birthdate: string = "生年月日"

  readonly sex: string = "性別"

  readonly company: string = "会社名"

  readonly depart: string = "部署名"

  readonly post: string = "役職"

  readonly existingCustomerFlg: string = "顧客分類"

  readonly mediaCode: string = "メディアコード"

  readonly deliveryCompany: string = "配送業者"

  readonly shopItemId: string = "加盟店商品ID"

  readonly itemName: string = "商品名"

  readonly price: string = "単価"

  readonly quantity: string = "数量"

  readonly category: string = "商品カテゴリ"

  readonly stock: string = "在庫有無"

  readonly cookie: string = "Cookie"

  readonly primaryDid: string = "デバイスID"

  readonly ipaddress: string = "IPアドレス"

  readonly domainName: string = "IPドメイン"

  readonly lineJName: string = "回線種別"

  readonly countryJName: string = "IP国情報"

  readonly prefCityJName: string = "IP地域情報"

  readonly cityLatitudeLongitude: string = "緯度/経度"

  readonly timeZone: string = "タイムゾーン"

  readonly deviceType: string = "デバイス種別"

  readonly useragent: string = "ユーザーエージェント文字列"

  readonly browserLanguage: string = "言語設定"

  readonly authoriId: string = "O-motion審査ID"

  readonly result: string = "O-motion審査結果"

  readonly userDeviceId: string = "端末ID"

  readonly didShort: string = "DID short"

  readonly didMiddle: string = "DID middle"

  readonly countryAName: string = "国名（英語表記）"

  readonly prefAName: string = "都道県名（英語表記）"

  readonly issuer: string = "カード発行元"

  readonly cardBrand: string = "カードブランド"

  readonly bincode: string = "BIN"

  readonly cchash: string = "カード番号ハッシュ"

  readonly expirationDate: string = "有効期限"

  readonly authoriCode: string = "オーソリ結果コード"

  readonly authoriComment: string = "オーソリ結果コメント"

  readonly paymentCount: string = "支払回数"

  readonly memo: string = "メモ"
}
