import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CsvAuthoriHistory]。
 */
export const CsvAuthoriHistoryRouteConfig: RouteConfig = {
  path: "/CsvAuthoriHistory",
  name: "CsvAuthoriHistory",
  component: () => import("%%/views/csvAuthoriHistory/CsvAuthoriHistory.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
