import { ValidationMessageTemplate, ValidationRuleFunction, ValidationRuleSchema } from "vee-validate/dist/types/types"

import { ipAddressV4SubnetValidator } from "./ipAddressV4SubnetValidator"
import { customMessage } from "./validatorUtil"

export class IpAddressV4RuleSubnetSchema implements ValidationRuleSchema {
  validate: ValidationRuleFunction = (value, params) => {
    return ipAddressV4SubnetValidator(value, params)
  }
  message: ValidationMessageTemplate = (field: string, params?: Record<string, any>) => {
    return customMessage(field, params)
  }
}
